// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    baseApiUrl:`https://centos1.propropz.net/`,
    production: false,
    talkjs:{
      appId: "tLYKmOio",
    },
    initial_route:"login",
    app_version:"04.00.335-T",
    admob_app_id:"ca-app-pub-5474366311557908~1880456713",
    ios_admob_app_id:'ca-app-pub-5474366311557908~9518972267',
    android_google_map_key:"AIzaSyDjOYLgD-5aHhASmWFiuLxauX5P6jgEBis",
    ios_google_map_key:"AIzaSyAXaIHt5tndDurqfi09abnBR5IZOTDOtSY",
    talk_js_bearer_api_key:"sk_test_uBQkcMmvW7msXjwYDnk8dH5hexLvZh2R",
    fd_login_url : `https://www.propropz.com/sso/`,
    fd_api_url : `https://proppydev.freshdesk.com/`
  };
  export const url = 'https://api.propropz.net/api';
  export const base_path = 'https://api.propropz.net/api/'
  export const payment_portal = "https://advertiser.proppyapp.biz/webtest/"
  export const cdn_portal = "https://cdn.propropz.net/"
  export const faq_portal = "https://support.proppyapp.com"
  export const deep_link_base_url = "https://propropz.com/"
  export const deep_link_hostname = "propropz.com"
  export const new_deep_link_base_url = "https://propropz.com/?AccessID="
  export const proppy_web_url ="https://propropz.com/"
  export const c360_web_url ="https://web.c360.life/"
  export const zoloz_api_url = "https://ekyc.proppyapp.net/"
  export const zoloz_h5_url = "https://www.proppyapp.biz/ZoloZ/ekyc.html"
  export const proffis_deep_link_base_url = "https://proffis.dev/?AccessID="
  export const PROFFICE_API_URL = 'https://profficenet.propropz.net/api/'
  export const STATIC_API_TOKEN = "cNePnlMRjx63daDd0bU0X33F94cCdvj3zgVEP41y9EeEAzpJh5OgWtJ8r18MZPYAzx"
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  