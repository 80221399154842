import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { AlertHandlerService } from 'src/app/services/alert-handler.service';
import { ApiService } from 'src/app/services/api.service';
import { DataFetcherService } from 'src/app/services/data-fetcher/data-fetcher.service';
import { StorageService } from 'src/app/services/storage.service';
import { ReviewRatingDetailsComponent } from 'src/app/uicomponents/review-rating-details/review-rating-details.component';

@Component({
  selector: 'app-doc-selection-popover',
  templateUrl: './doc-selection-popover.component.html',
  styleUrls: ['./doc-selection-popover.component.scss'],
})
export class DocSelectionPopoverComponent implements OnInit {
  doc_list:any = [
    { type:1,icon:'beep',title:'Contact Merchant',doc_css:'papb1',show:true},
    { type:2,icon:'viewhistory',title:'View',doc_css:'papy2 border-top-default border-bottom-default',show:true},
    { type:3,icon:'visibility-closed-icon',title:'Hide',doc_css:'papt1',show:true},
    { type:4,icon:'visibility-icon',title:'Show',doc_css:'papt1',show:false},
  ];
  item : any;
  userId : any;
  tabType : number = 1;
  hideStatus:number=0;
  page:string='';
  // selling_product_menu = [];
  // service_product_menu = [];
  // cartDetails = {};
  // merchantDetails = {};
  // selected_tab: number = 1;
  // Locations = [];
  // merchantImagesExist = 0;
  // open_membership_signup = 0;
  // totalPrice: number = 0
  // selected_prod_count = 0
  // isRefreshed = false;
  merchantID:string=''
  tab_images = [
    { img: 'merchant-page-menutab-icon-products', title: 'Products', type: 1, is_allowed: false },
    { img: 'merchant-details-tab-btn-service', title: 'Services', type: 2, is_allowed: false },
    { img: 'merchant-page-menutab-icon-photo', title: 'Photos', type: 3, is_allowed: true },
    { img: 'merchant-page-menutab-icon-video', title: 'Videos', type: 4, is_allowed: true },
    { img: 'merchant-page-menutab-icon-voucher', title: 'Voucher', type: 5, is_allowed: false },
    { img: 'merchant-page-menutab-icon-about', title: 'About', type: 6, is_allowed: true },
  ]
  constructor(
    private storageService: StorageService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private ref: ChangeDetectorRef,
    private http: DataFetcherService,
    private alertHandler: AlertHandlerService,
    private sanitizer: DomSanitizer,
    private modalCtrl:ModalController,
    private popCtrl:PopoverController,
    public router: Router,
  ) {}
  ngOnInit() {
    if(this.page.length>0 && this.page == 'public_profile'){
      this.doc_list = [
        { type:1,icon:'beep',title:'Contact Merchant',doc_css:'papb1',show:true},
        { type:2,icon:'viewhistory',title:'View',doc_css:'papy2 border-top-default border-bottom-default',show:true},
      ];
    }
    if(this.hideStatus == 0){
      this.doc_list[2]['show'] = !this.doc_list[2]['show'];
      this.doc_list[3]['show'] = !this.doc_list[3]['show'];
    }
  }

  doAction(option:any,itemObj:any){
    switch(option['type']){
      case 1: this.beepMerchant(option['type'],itemObj);
              break;
      case 2: (this.tabType == 1 ? this.openReviewRatingModal(option['type'],itemObj) : this.redirectToProductReviewRating(option['type'],itemObj))
              break;
      case 3: 
      case 4: this.hideShow(option['type'],itemObj);
              break;
      default : alert('nothing...')
    }
  }

  // Case 1
  async beepMerchant(type:number,obj:any) {
    var data = (this.tabType == 1 ? obj?.['merchant_info'] : obj?.['product_info'])
    data.name = (this.tabType == 1 ? obj?.['merchant_info']?.['company_name'] : obj?.['product_info']?.['prod_name'])
    const participants = { "UserID": data['created_by'], "Name": data['created_by_name'] || data['created_by'], "ProfilePicURL": data['image_url'] }
    var conversationData = {
      "GroupName": "AccountReviewRating : " + data.name,
      "GroupId": (data.merchant_id + this.userId), "Participants": [participants]
    }
    await this.storageService.setData("newConversation", conversationData);
    this.navCtrl.navigateForward(['group-chat']);
    this.dismiss(type,obj)
  }

  // Case 2
  async openReviewRatingModal(type:number,obj:any) {
    this.merchantID = obj['merchant_info']['merchant_id'];
    const modal = await this.modalController.create({
      component: ReviewRatingDetailsComponent,
      cssClass:'bg_white',
      mode: 'md',
      componentProps: {
        merchant_data: obj['merchant_info'],
        ObjectType: 'Merchant',
        ObjectID: obj['merchant_info']['merchant_id'],
        isModal: true
      },
      presentingElement: await this.modalController.getTop(),
    });
    await modal.present();
    await modal
      .onWillDismiss()
      .then((result) => {
        this.dismiss(type,obj)
        //this.getMerchantDetails()
      })
      .catch((err) => { });
  }

  // getMerchantDetails() {
  //   this.http.doPost(ApiService.GET_PREMIUM_MERCHANT_DETAILS, { 'merchant_id': this.merchantID }).subscribe((res) => {
  //     const status = res['status'] || false;
  //     if (status) {
  //       this.selling_product_menu = [];
  //       this.service_product_menu = [];
  //       if (res['data'].length > 0) {
  //         var merchant_data = res['data'][0] || {};
  //         this.selling_product_menu = JSON.parse(JSON.stringify(merchant_data['selling_product_menu'])) || [];
  //         this.service_product_menu = JSON.parse(JSON.stringify(merchant_data['service_product_menu'])) || [];
  //         this.productCalc(0);
  //         var youtubeUrls = (merchant_data['merchant_assets'] || []).filter(f => f['type'] == 'YoutubeVideoLink')
  //         merchant_data['merchant_profilepic'] = (merchant_data['merchant_assets'] || []).filter(f => f['type'] == 'ProfilePicture')
  //         merchant_data['banner_images'] = (merchant_data['merchant_assets'] || []).filter(f => f['type'].startsWith('ShopBanner'))
  //         merchant_data['banner_images'].sort(function(a, b){return a['sequence'] - b['sequence']});
  //         merchant_data['youtubeUrls'] = []
  //         youtubeUrls.map(url => {
  //           merchant_data['youtubeUrls'].push(this.getMediaContent(url['url']))
  //         })
  //         merchant_data['merchant_loc'] = (`${(merchant_data['address_line_1'] || '')} ${(merchant_data['address_line_2'] || '')} ${(merchant_data['post_code'] ? merchant_data['post_code'] + ', ' : ' ')}${(merchant_data['state'] ? merchant_data['state'] + ', ' : ' ')}${(merchant_data['country'] || '')}`).trim();
  //         this.merchantDetails = merchant_data
  //         if ((this.merchantDetails['is_order_no_configured'] > 0) && (this.merchantDetails['is_sys_params_configured'] > 0) && (this.merchantDetails['is_premium_active'] == 1)) {
  //           this.tab_images[0]['is_allowed'] = true;
  //           this.tab_images[1]['is_allowed'] = true;
  //           this.tab_images[4]['is_allowed'] = true;
  //           this.selected_tab = this.selected_tab == 6 ? 1 : this.selected_tab;
  //           this.ref.detectChanges()
  //         } else {
  //           this.selected_tab = 6
  //         }
  //         this.Locations = res["data"][0]["locations"];
  //         this.checkoutValue()
  //         this.onRatingRefresh()
  //         if (this.open_membership_signup == 1) {
  //           if ((this.merchantDetails['member_since'] || '').length == 0) {
  //             setTimeout(() => {
  //               this.goToMembershipSignup()
  //             }, 1500);
  //           } else {
  //             this.alertHandler.presenToast('Already a Member Since ' + this.merchantDetails['member_since'] + '.')
  //           }
  //         }
  //         if (this.merchantDetails['plan_type'] == "Basic" && this.merchantDetails['images'].length > 2) {
  //           this.merchantDetails['images'] = this.merchantDetails['images'].slice(0, 2);
  //           this.merchantImagesExist = 2;
  //         } else {
  //           this.merchantDetails['images'] = this.merchantDetails['images'];
  //           this.merchantImagesExist = res.length;
  //         }
  //         // REMOVING OF SELECTED TAB FROM URL 
  //         // if (this.activatedRoutes.snapshot.queryParams.hasOwnProperty('selected_tab')) {
  //         //   this.router.navigate([], { relativeTo: this.activatedRoutes, queryParams: { merchant_id: this.merchantID }, replaceUrl: true });
  //         // }
  //         this.ref.detectChanges();
  //       }
  //     } else {
  //       this.alertHandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG)
  //     }
  //   }, (error) => {
  //     this.alertHandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG)
  //   })
  // }

  // productCalc(type: number) {
  //   /*****
  //    * 0 - All
  //    * 1 - product
  //    * 2 - service 
  //    *****/
  //   if (type == 0 || type == 1) {
  //     if ((this.selling_product_menu || []).length > 0) {
  //       this.selling_product_menu.map(item => {
  //         if (this.cartDetails[item['product_id']]) {
  //           item['qty'] = this.cartDetails[item['product_id']]['qty'] || 0
  //         } else {
  //           item['qty'] = 0;
  //         }
  //         item['total_loyality_points'] = (Number(item['unit_price']) * Number(item['loyal_point_validity_value'] || 0));
  //       })
  //     }
  //   }
  //   if (type == 0 || type == 2) {
  //     if ((this.service_product_menu || []).length > 0) {
  //       this.service_product_menu.map(item => {
  //         if (this.cartDetails[item['sp_id']]) {
  //           item['qty'] = this.cartDetails[item['sp_id']]['qty'] || 0;
  //         } else {
  //           item['qty'] = 0;
  //         }
  //         item['total_loyality_points'] = (Number(item['basic_service_charge']) * Number(item['loyal_point_validity_value'] || 0));
  //       })
  //       this.serviceAvail();
  //     }
  //   }
  // }

  // checkoutValue() {
  //   this.totalPrice = 0
  //   this.selected_prod_count = 0;
  //   this.selling_product_menu.map(item => {
  //     this.totalPrice += (item['unit_price'] * (item['qty'] || 0));
  //     this.selected_prod_count += (item['qty'] || 0);
  //   })
  //   this.totalPrice = Number(this.totalPrice.toFixed(2))
  //   this.ref.detectChanges()
  // }

  // onRatingRefresh() {
  //   this.isRefreshed = true;
  //   setTimeout(() => {
  //     this.isRefreshed = false
  //   }, 1000);
  // }

  // goToMembershipSignup() {
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: {
  //       merchant_id: this.merchantID,
  //       open_membership_signup: this.open_membership_signup || 0
  //     }
  //   };
  //   this.navCtrl.navigateForward(['tabs/premium-merchant/add-member'], navigationExtras);
  // }

  // getMediaContent(url) {
  //   var vID = this.youtube_parser(url);
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(
  //     "https://www.youtube-nocookie.com/embed/" + vID
  //   );
  // }

  // youtube_parser(url) {
  //   var match = ''
  //   if (url.includes("v=")) {
  //     match = url.split("v=")[1]
  //   } else if (url.includes("shorts/")) {
  //     match = url.split("shorts/")[1]
  //   } else {
  //     match = url.split("/")[3];
  //   }
  //   return match && match.length == 11 ? match : false;
  // }

  // serviceAvail() {
  //   let data = JSON.parse(JSON.stringify(this.service_product_menu)).filter(f => f['qty'] > 0);
  //   if (data.length > 0) {
  //     this.service_product_menu.map(o => {
  //       if (o['qty'] == 0) o['is_avail'] = 'pointer_events_none opac-full';
  //     })
  //   } else {
  //     this.service_product_menu.map(o => { o['is_avail'] = ''; })
  //   }
  //   this.ref.detectChanges();
  // }

  redirectToProductReviewRating(type:number,obj:any){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        product_id: obj['product_info']['product_id'],
        merchant_id : obj['product_info']['merchant_id']
      }
    };
    this.router.navigate(["/tabs/premium-merchant/product-details"],navigationExtras);
  }

  // Case 3
  hideShow(type:number,obj:any){
    this.http.doPut(ApiService.ADD_REVIEW_RATING, { id: obj['id'],allow_public_view:(type == 3 ? 0 : 1 )}).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        let msg = (type == 3 ? 'hide' : 'shown')
        this.alertHandler.presenToast(`status changed successfully`);
        this.dismiss(type,obj)
      } else {
        this.alertHandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);
      }
    },(error) => {
      this.alertHandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
    });
  }

  dismiss(confirm_type,obj:number){
    if(confirm_type == 3 || confirm_type == 4){
      this.doc_list[2].show = !this.doc_list[2].show
      this.doc_list[3].show = !this.doc_list[3].show
      // this.popCtrl.dismiss({'confirm': confirm_type,'hide_status':(this.hideStatus == 1 ? 0 : 1),'object':obj});
    }
    this.popCtrl.dismiss({'confirm': confirm_type,'object':obj});
  }
}