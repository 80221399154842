import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment, base_path, cdn_portal, STATIC_API_TOKEN, PROFFICE_API_URL } from "../../environments/environment";
import { AppHelper } from "./middleware/app-helper";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  static NodeApiUrl = environment.baseApiUrl;
  static BASE_NODE_URL = `${ApiService.NodeApiUrl}po-node/`;
  static BILLING_BASE_NODE_URL = `${ApiService.NodeApiUrl}billing-node/`;
  static EKYC_API_URL = `${ApiService.NodeApiUrl}ekyc-node/`;
  static MARKET_360_NODE_URL = `${ApiService.NodeApiUrl}m360-node/`;
  static BASE_P360_NODE_API_URL = `${ApiService.NodeApiUrl}p360-node/`;
  static basePythonApiUrl = `${ApiService.NodeApiUrl}ae-wl/`;
  static AUTH_NODE_URL = `${ApiService.NodeApiUrl}auth-node/`;
  static SHARED_NODE_URL = `${ApiService.NodeApiUrl}shared-node/`;
  static SIGNATRUE_NODE_URL = `${ApiService.NodeApiUrl}signatrue-node/`;
  static WPE_NODE_URL: string = `${ApiService.NodeApiUrl}wpe-node/`;
  
  static BASE_HOMES_NODE_URL = `${ApiService.NodeApiUrl}homes-node/`;
  public static STATIC_API_TOKEN = STATIC_API_TOKEN
  public static base_path = base_path;
  public static PROFFICE_API_URL = PROFFICE_API_URL
  public static AGENT_ROLE_ID = 24
  public static REN_APP_ID = 10;
  public static headerTitle = "ProppyApp"
  static VEHICLE_TYPES = [
    { id: 0, name: "Car" },
    { id: 1, name: "Motorcycle" },
    { id: 2, name: "Suv" },
    { id: 3, name: "Van" },
    { id: 4, name: "Lorry" },
    { id: 5, name: "Others" },
  ];
  static RESIDENT_STATUS = [
    { id: 0, name: "Owner,Resident" },
    { id: 1, name: "Owner" },
    { id: 2, name: "Resident" },
    { id: 3, name: "Tenanat" },
  ];
  static LOCATION_STATUS = [
    { id: 0, name: "Kuala Lampur" },
    { id: 1, name: "Jalan" },
  ];
  static CATEGORY_STATUS = [
    { id: 0, name: "washing machine" },
    { id: 1, name: "TV" },
  ];
  static SORTBY_STATUS = [
    { id: 1, name: "Newest First" },
    { id: 2, name: "Oldest First" },
  ];
  /************** USER SERVICES START ******************/
  public static ADD_USER_ADDRESS =
    ApiService.BASE_NODE_URL + "member_address_book";
  public static GET_USER_ADDRESS =
    ApiService.BASE_NODE_URL + "member_address_book/details";

  /************** USER SERVICES END ******************/
  public static GET_MEMBER_BADGES =
    ApiService.BASE_NODE_URL + "my_account/master_badges/details";
  public static path_UserValidation =
    ApiService.base_path + "Proppy/GetJWTToken";
  public static path_GetCountries =
    ApiService.base_path + "Proppy/GetCountries";
  public static GetCountryCodes =
    ApiService.BASE_NODE_URL + "bo_masters/countries/details";
  public static path_Newregistration =
    ApiService.base_path + "Proppy/NewUserRegistration";
  public static path_PasswordReset =
    ApiService.base_path + "Proppy/MemberExistCheck";
  public static path_PasswordUpdaten =
    ApiService.base_path + "Proppy/MemberUpdatePassword";
  public static path_GetSelectedCountryExtenstion =
    ApiService.base_path + "Proppy/GetSelectedCountryCode";
  public static path_GetOTP = ApiService.base_path + "Proppy/GenerateOTP";
  public static path_VerifyOTP = ApiService.base_path + "Proppy/VerifyOTP";
  public static path_GetUserInformation =
    ApiService.base_path + "Proppy/GetUserInformation";
  public static GETMEMBERBYPROPPYUSERID =
    ApiService.base_path + "Proppy/GetMemberByProppyUserID";
  public static UPDATE_MEMBER_INFORMATION =
    ApiService.base_path + "Proppy/UpdateMemberInformation";
  public static ADD_MEMBER_ADDRESS =
    ApiService.base_path + "Proppy/AddMemberAddress";
  public static UPDATE_MEMBER_ADDRESS =
    ApiService.base_path + "Proppy/UpdateMemberAddress";
  public static GET_MEMBER_ADDRESS =
    ApiService.base_path + "Proppy/GetMemberAddress";
  // public static GET_MEMBER_BADGES =
  //   ApiService.base_path + "Proppy/GetUserBadges";
  public static GET_MY_REFERRALS =
    ApiService.base_path + "Proppy/GetMyReferrals";
  public static GET_AMZ_RACE_INV =
    ApiService.base_path + "Proppy/GetAmzRaceInv";
  public static GET_AMZ_RACE_DETAILS =
    ApiService.BASE_NODE_URL + "amazing_race/amz_race_inv/details";

  public static ADD_ACCOUNT_DELETE =
    ApiService.BASE_NODE_URL + "account_delete/member_delete_request";
  public static GET_ACCOUNT_DELETE_DETAILS =
    ApiService.BASE_NODE_URL + "account_delete/member_delete_request/details";

  // public static LOCATION_IQ_API_ACCESS_TOKEN="pk.ef9ccd4cfb54ea6da6e9a52f3152350a";
  // public static LOCATION_IQ_Reverse_API_URL="https://us1.locationiq.com/v1/reverse.php?key="+ApiService.LOCATION_IQ_API_ACCESS_TOKEN;
  // public static LOCATION_IQ_AUTOCOMPLETE_API_URL="https://api.locationiq.com/v1/autocomplete.php?key="+ApiService.LOCATION_IQ_API_ACCESS_TOKEN+"&q=";
  // public static LOCATION_IQ_API_RESPONSE_FORMAT="&format=json"

  // public static GET_STATES=ApiService.base_path+'MarketPlace/GetStates'
  // public static GET_COUNTRIES=ApiService.base_path+'MarketPlace/GetCountries'
  // public static GET_PROPERTY_TYPE=ApiService.base_path+'MarketPlace/GetPropertyType'
  // public static GET_PROPERTY_PRICING=ApiService.base_path+'MarketPlace/GetPropertyPricing'
  // public static GET_BEDROOM_TYPES=ApiService.base_path+'MarketPlace/GetBedroomTypes'
  // public static GET_PROPERTY_SALE_TYPE=ApiService.base_path+'MarketPlace/GetPropertySaleType'
  // public static GET_AREAS=ApiService.base_path+'MarketPlace/GetAreas'
  // public static GET_PROPERTIES_BY_FILTER=ApiService.base_path+'MarketPlace/GetPropertiesByFilter';
  // public static GET_PROPERTY_IMAGES=ApiService.base_path+'MarketPlace/GetPropertyImages';
  public static ADD_PRO_APP_USER_ROLES =
    ApiService.BASE_NODE_URL + "bo_settings/pro_app_user_roles";
  public static GET_PROFILE_INFO = ApiService.BASE_P360_NODE_API_URL +'dashboard/agent_profile/details';

  public static GET_PROPERTY_AGENT =
    ApiService.base_path + "ProppyAgent/GetPropertyAgentDetails";
  public static GET_PROPERTY_AGENT_MARKETPLACE =
    ApiService.base_path + "ProppyAgent/GetAgentProperties";
  public static GET_PROPERTY_AGENT_MARKETPLACEV2 =
    ApiService.base_path + "ProppyAgent/GetAgentPropertiesV2";
  public static GENERATE_OTP_SERVICE =
    ApiService.base_path + "ProppyAgent/GenerateOTPForAddAgent";
  public static VERIFY_OTP_SERVICE =
    ApiService.base_path + "ProppyAgent/VerifyOTP";
  public static UPDATE_PROPERTY_AGENT_DETAILS =
    ApiService.base_path + "ProppyAgent/UpdatePropertyAgentDetails";
  public static GET_PROPERTIES_COUNT =
    ApiService.base_path + "ProppyAgent/GetAgentPropertiesCount";
  public static GET_PROPERTY_AGENT_PROPERTY_TYPE =
    ApiService.base_path + "ProppyAgent/GetAgentPropertyType";
  public static GET_PROPERTY_AGENT_AREA_EXPERTISE =
    ApiService.base_path + "ProppyAgent/GetAgentAreaExpertise";
  public static GET_PROPERTY_AGENT_FOR_OWNER =
    ApiService.base_path + "ProppyAgent/GetPropertyAgentForOwner";
  public static ADD_PROPERTY_GET_AGENT =
    ApiService.base_path + "ProppyAgent/AddPropertyGetAgent";
  public static GET_ASSIGNED_PROPERTY_AGENT_FOR_OWNER =
    ApiService.base_path + "ProppyAgent/GetAssignedPropertyAgentForOwner";
  public static UPDATE_PROPERTY_GET_AGENT_STATUS =
    ApiService.base_path + "ProppyAgent/UpdatePropertyGetAgentStatus";

  public static GET_AGENT_BULLETIN_BOARD_BY_AGENTID =
    ApiService.base_path + "ProppyAgent/GetAgentBulletinBoardByAgentID";

  public static GET_AGENT_BULLETIN_BOARD_DETAILS_BY_ID =
    ApiService.base_path + "ProppyAgent/GetAgentBulletinBoardDetailsByID";
  // Agent api V2
  public static GET_AGENT_DETAILS =
    ApiService.base_path + "ProppyAgent/GetAgentDetails";
  public static GET_AGENT_PROPERTIES_V2 =
    ApiService.base_path + "ProppyAgent/GetAgentPropertiesV3";
  public static GET_AGENT_BY_RENTAG =
    ApiService.base_path + "ProppyAgent/GetAgentInfoByRentagNo";

  public static GET_AGENT_BULLETIN_BOARD_DETAILS_BY_PAGING =
    ApiService.BASE_NODE_URL + "bulletin_board/get_an_agent/paging";
  public static GET_AGENT_BULLETIN_BOARD_DETAILS =
    ApiService.BASE_NODE_URL + "bulletin_board/get_an_agent/details";

  // end

  public static PROPPY_CARES_PREFIX = ApiService.base_path + "ProCares/";
  public static PROPERTY_MARKET_PREFIX =
    ApiService.base_path + "PropertyMarket/";

  public static GET_MERCHENT_DETAILS_BY_MERCHENTID =
    ApiService.PROPPY_CARES_PREFIX + "GetMerchentDetailsByMerchentID";

  public static LOCATION_IQ_API_ACCESS_TOKEN =
    "pk.ef9ccd4cfb54ea6da6e9a52f3152350a";
  public static LOCATION_IQ_Reverse_API_URL =
    "https://us1.locationiq.com/v1/reverse.php?key=" +
    ApiService.LOCATION_IQ_API_ACCESS_TOKEN;
  public static LOCATION_IQ_SEARCH_API_URL =
    "https://us1.locationiq.com/v1/search.php?key=" +
    ApiService.LOCATION_IQ_API_ACCESS_TOKEN;
  public static LOCATION_IQ_AUTOCOMPLETE_API_URL =
    "https://api.locationiq.com/v1/autocomplete.php?key=" +
    ApiService.LOCATION_IQ_API_ACCESS_TOKEN +
    "&q=";
  public static LOCATION_IQ_API_RESPONSE_FORMAT = "&format=json";
  public static DROP_DOWN_DATA_SEND_KEY = "drop_down_send_data";
  public static DROP_DOWN_DATA_RESPONSE_KEY = "drop_down_response_data";
  public static MARKETPLACE_FILTER_PARAMS = "marketplace_filter_params";
  public static LOCATION_FILTER_HISTORY = "location_filter_history_arr";
  public static PROPPY_AGENT_ID = "Proppy_Agent_ID";
  public static FCM_TOKEN_KEY = "FCM_TOKEN_KEY";
  public static RESIDENTS_DATA = "residents_data";
  public static REASON_SELECTED_ACCOUNT_DELETE = "sendParams";
  public static NOTIFICATION_PARAMS = "NOTIFICATION_PARAMS";

  public static Default_country_code = "MY";
  public static Default_country_name = "Malaysia";

  // LOCAL DB
  public static LBDB_PUBLIC_PRIVATE = "public_private";

  //Marketplace
  public static GET_STATES = ApiService.base_path + "PropertyMarket/GetStates";
  public static GET_COUNTRIES =
    ApiService.base_path + "PropertyMarket/GetCountries";
  public static GET_PROPERTY_TYPE =
    ApiService.base_path + "PropertyMarket/GetPropertyType";
  public static GET_PROPERTY_TYPE_BY_NAME =
    ApiService.base_path + "PropertyMarket/GetPropertTypeByName";
  public static GET_PROPERTY_PRICING =
    ApiService.base_path + "PropertyMarket/GetPropertyPricing";
  public static GET_BEDROOM_TYPES =
    ApiService.base_path + "PropertyMarket/GetBedroomTypes";
  public static GET_PROPERTY_SALE_TYPE =
    ApiService.base_path + "PropertyMarket/GetPropertySaleType";
  public static GET_AREAS = ApiService.base_path + "PropertyMarket/GetAreas";
  public static GET_PROPERTIES_BY_FILTER =
    ApiService.base_path + "PropertyMarket/GetPropertiesByFilter";
  public static GET_PROPERTIES_BY_FILTERV2 =
    ApiService.base_path + "PropertyMarket/GetPropertiesByFilterV2";
  public static GET_PROPERTIES_BY_FILTERMAPV2 =
    ApiService.base_path + "PropertyMarket/GetPropertiesByFilterMapV2";
  public static GET_PROPERTIES_BY_LISTINGID =
    ApiService.base_path + "PropertyMarket/GetPropertyByListingID";
  public static GET_PROPERTIES_BY_LISTINGIDV2 =
    ApiService.base_path + "PropertyMarket/GetPropertyByListingIDV2";
  public static GET_PROPERTY_IMAGES =
    ApiService.base_path + "PropertyMarket/GetPropertyByListingID";
  public static GET_FEATURES =
    ApiService.base_path + "PropertyMarket/GetPropertyFeatures";
  public static GET_FACILITIES =
    ApiService.base_path + "PropertyMarket/GetPropertyFacility";
  public static LIST_PROPERTY_TO_MARKET =
    ApiService.base_path + "PropertyMarket/ListProperty";
  public static LIST_PROPERTY_TO_MARKET_V2 =
    ApiService.base_path + "PropertyMarket/ListPropertyV2";
  public static UPDATE_LIST_PROPERTY_TO_MARKET =
    ApiService.base_path + "PropertyMarket/UpdateProperty";
  public static UPDATE_LIST_PROPERTY_TO_MARKET_V2 =
    ApiService.base_path + "PropertyMarket/UpdatePropertyV2";
  public static PUBLISH_PROPERTY_TO_MARKET =
    ApiService.base_path + "PropertyMarket/PublishProperty";
  public static PUBLISH_PROPERTY_TO_MARKETV2 =
    ApiService.base_path + "PropertyMarket/PublishPropertyV2";
  public static DELIST_PROPERTY_TO_MARKET =
    ApiService.base_path + "PropertyMarket/DelistProperty";
  public static GET_PROPERTY_MARKET_IMAGES =
    ApiService.base_path + "PropertyMarket/GetPropertyImages";
  public static GET_PROPERTY_MARKET_IMAGES_COUNT =
    ApiService.base_path + "PropertyMarket/GetPropertyMediaCount";
  public static GET_PROPERTY_MARKET_IMAGES_BY_MEDIATYPE =
    ApiService.base_path + "PropertyMarket/GetPropertyMediaByType";
  public static GET_PROPERTY_MARKET_IMAGES_BY_MEDIABYID =
    ApiService.base_path + "PropertyMarket/GetPropertyMediaByID";

  public static ADD_PROPERTYMARKET_USER_FAVOURITE =
    ApiService.base_path + "PropertyMarket/AddPropertyMarketUserFavourite";
  public static UPDATE_PROPERTYMARKET_USER_FAVOURITE =
    ApiService.base_path + "PropertyMarket/UpdatePropertyMarketUserFavourite";

  public static ADD_FAVOURITE_DETAILS_LIST = ApiService.BASE_NODE_URL + "favorites/details";
  public static UPDATE_FAVOURITES_LIST = ApiService.BASE_NODE_URL + "favorites"
  public static GET_PROPERTY_KEYS_SEQUENCE = ApiService.BASE_NODE_URL + "ph_property_compare/pc_user_columns/details"
  public static ADD_UPDATE_PROPERTY_KEYS_SEQUENCE = ApiService.BASE_NODE_URL + "ph_property_compare/pc_user_columns"
  public static GET_FAVOURITES_LIST =
    ApiService.base_path + "PropertyMarket/GetPropertyMarketUserFavourite";
  public static GET_USER_FAVOURITE_BY_LISTINGID =
    ApiService.base_path + "PropertyMarket/GetUserFavouriteByListingID";

  public static UPLOAD_PROPERTY_MARKET_IMAGES =
    ApiService.base_path + "PropertyMarket/AddPropertyMarketImages";

  public static UPLOAD_PROPERTY_MARKET_IMAGES_FILE =
    ApiService.base_path + "PropertyMarket/AddPropertyMarketImagesFile";
  public static DELETE_PROPERTY_MARKET_IMAGES =
    ApiService.base_path + "PropertyMarket/DeletePropertyMarketImages";

  public static DHWL_PREFIX = ApiService.base_path + "DHWL/";
  public static GET_CURRENCIES =
    ApiService.DHWL_PREFIX + "GetCountryCurrencies";
  public static GET_FACING_DIRECTIONS =
    ApiService.DHWL_PREFIX + "GetFacingDirections";
  public static GET_DHWL_BY_PROPPY_ID = ApiService.DHWL_PREFIX + "GetDHWL";
  public static GET_DHWL_BY_DHWLID = ApiService.DHWL_PREFIX + "GetDHWLByDHWLID";
  public static ADD_DHWL = ApiService.DHWL_PREFIX + "AddDHWL";
  public static UPDATE_DHWL = ApiService.DHWL_PREFIX + "UpdateDHWL";
  public static UPDATE_DHWL_STATUS =
    ApiService.DHWL_PREFIX + "UpdateDHWLStatus";



  public static UPDATE_BADGES_EYE_STATUS =
    ApiService.BASE_NODE_URL + "my_account/user_badges";
  public static GET_BADGES_EYE_DETAILS =
    ApiService.BASE_NODE_URL + "my_account/user_badges/details";
  public static SAVE_OR_UPDATE_EMPLOYMENT =
    ApiService.BASE_NODE_URL + "my_account/employment";
  public static GET_EMPLOYMENT_DETAILS =
    ApiService.BASE_NODE_URL + "my_account/employment/details";


  public static MYACCOUNT_PREFIX = ApiService.base_path + "MyAccount/";
  public static GET_AREA_OF_INTERESTS =
    ApiService.BASE_NODE_URL + "my_account/account/" + "area_of_interest";
  public static GET_MYPROIFLE =
    ApiService.BASE_NODE_URL + "my_account/account/" + "profile_by_propper_id";
  public static GET_UPDATE_MEMBER_PROFILE =
    ApiService.BASE_NODE_URL + "my_account/account/" + "update_profile";

  public static GET_REFERRAL =
    ApiService.MYACCOUNT_PREFIX + "GetReferralByproppyID";
  public static GET_GEMS = ApiService.MYACCOUNT_PREFIX + "GetGemsByProppyID";
  public static GET_SIGNUPGEMS =
    ApiService.MYACCOUNT_PREFIX + "GetCheckUpdateProfileGemsByProppyID";
  public static GET_UPDATE_REFERRAL =
    ApiService.MYACCOUNT_PREFIX + "UpdateProppyReferralID";
  public static GET_UPDATE_PROFILE =
    ApiService.MYACCOUNT_PREFIX + "UpdateProfile";
  public static GET_TRX_PROPPYGEM =
    ApiService.MYACCOUNT_PREFIX + "InsertProppyGEMTrx";
  // public static GET_MYPROIFLE =
  //   ApiService.MYACCOUNT_PREFIX + "GetProfileByproppyID";
  public static GLOBALSEARCH_GET =
    ApiService.MYACCOUNT_PREFIX + "/GlobalSearchPrefGet";
  public static GLOBALSEARCH_SET =
    ApiService.MYACCOUNT_PREFIX + "/GlobalSearchPrefSet";
  public static GET_APPVERSION =
    ApiService.MYACCOUNT_PREFIX + "AppVersionCheck";

  // public static GET_UPDATE_MEMBER_PROFILE = ApiService.MYACCOUNT_PREFIX + "UpdateMemberProfile";
  // public static GET_AREA_OF_INTERESTS = ApiService.MYACCOUNT_PREFIX + "GetAreaOfInterest";

  public static KYC_PREFIX = ApiService.base_path + "KYC/";
  public static KYC_ADD = ApiService.KYC_PREFIX + "KYCAdd";
  public static GET_KYC = ApiService.KYC_PREFIX + "GetKYC";
  public static KYC_VERIFYID = ApiService.base_path + "Proppy/CheckIdentity";

  public static CAMPAIGNS_MGM_PATH =
    ApiService.MYACCOUNT_PREFIX + "/CampaignsGetMemberDeepLinks";

  public static GET_PROPPYCARES_CATEGORIES =
    ApiService.base_path +
    "ProppyCaresServices/GetProppyCaresServicesCategories";
  public static GET_PROPPYCARES_ALL_MERCHANTS =
    ApiService.base_path + "GopherMap/GetAllProppyCaresMerchants";
  public static GET_PROPPYCARES_ALL_MERCHANTS_V2 =
    ApiService.base_path + "GopherMap/GetAllProppyCaresMerchants_V2";
  public static DELETE_PROPPYCARES_LISTING =
    ApiService.base_path + "GopherMap/DeleteMerchant";
  public static UPDATE_PROPPYCARES_STATUS =
    ApiService.base_path + "GopherMap/UpdateMerchantStatus";
  public static GET_GOPHERMAP_CATEGORIES =
    ApiService.base_path + "ProGopherMap/Categories";
  public static GET_GOPHERMAP_CATEGORIES_GROUPS =
    ApiService.base_path + "ProGopherMap/CategoryGroups";
  public static UPLOAD_USERPROFILE_IMAGES_FILE =
    ApiService.base_path + "MemberProfile/AddMemberProfilePicture";
  public static GET_GOPHER_PREFERENCES =
    ApiService.base_path + "ProGopherMap/GetPreference";
  public static GET_GOPHER_PREFERENCES_V2 =
    ApiService.base_path + "ProGopherMap/GetPreferenceV2";
  public static ADD_GOPHER_PREFERENCES =
    ApiService.base_path + "ProGopherMap/AddPreference";
  public static ADD_GOPHER_PREFERENCES_V2 =
    ApiService.base_path + "ProGopherMap/AddPreferenceV2";
  public static GET_GOPHERMAP_DATA =
    ApiService.base_path + "ProGopherMap/GetData";
  //Start Review & Rating
  public static GET_INDIVIDUAL_RATINGS_COUNT =
    ApiService.base_path + "ReviewAndRating/GetIndividualRatingsCount";
  public static GET_TOTAL_RATINGS_AVERAGE =
    ApiService.base_path + "ReviewAndRating/GetRatingsAverage";
  public static GET_REVIEW_AND_RATING =
    ApiService.base_path + "ReviewAndRating/GetReviewAndRating";
  public static ADD_REVIEW_AND_RATING =
    ApiService.base_path + "ReviewAndRating/AddReviewAndRating";
  public static GET_REVIEW_RATING_TAGS =
    ApiService.base_path + "ReviewAndRating/GetReviewRatingTags";
  public static GET_SELECTED_REVIEW_RATING_TAG_COUNT =
    ApiService.base_path + "ReviewAndRating/GetSelectedReviewRatingTagCount";
  public static GET_REVIEW_RATING_HASHTAGS =
    ApiService.BASE_NODE_URL + "review_rating/review_rating_tags/details";
  public static ADD_REVIEW_RATING =
    ApiService.BASE_NODE_URL + "review_rating/review_and_rating";
  public static GET_REVIEW_RATING_DETAILS =
    ApiService.BASE_NODE_URL + "review_rating/review_and_rating/details"
  public static GET_REVIEW_RATING_COUNT =
    ApiService.BASE_NODE_URL + "review_rating/review_and_rating/getReviewRatingCount"
  public static REVIEW_RATING_IMAGE_UPLOAD = cdn_portal + 'ifile/api/File/UploadReviewRatingImage';
  public static REVIEW_RATING_IMAGE_UPLOAD_V2 = cdn_portal + 'ifile/api/File/Market360/UploadReviewRatingImage'

  public static UPDATE_ENDORSE = ApiService.base_path + "Endorse/UpdateEndorse";
  public static UPDATE_ENDORSEMENT = ApiService.BASE_NODE_URL + "endorse/endorsement";

  public static GET_ENDORSE_BY_USER_ID =
    ApiService.base_path + "Endorse/GetEndorseByUserID";
  public static GET_ENDORSEMENT_BY_USER_ID =
    ApiService.BASE_NODE_URL + "endorse/endorsement/get_endorse_by_user_id";

  public static GET_ENDORSE = ApiService.base_path + "Endorse/GetEndorse";
  public static GET_ENDORSE_DETAILS = ApiService.BASE_NODE_URL + "endorse/endorsement/details";

  public static ADD_ENDORSE = ApiService.base_path + "Endorse/AddEndorse";
  public static ADD_ENDORSEMENT = ApiService.BASE_NODE_URL + "endorse/endorsement";

  public static GET_OBJECT_DETAILS =
    ApiService.base_path + "Endorse/GetObjectDetails";
  public static GET_ENDORSEMENT_OBJECT_DETAILS =
    ApiService.BASE_NODE_URL + "endorse/endorsement/get_object_details";

  public static GET_ENDORSEMENTS_PAGING =
    ApiService.base_path + "Endorse/GetEndorsementsPaging";
  public static GET_ENDORSEMENT_PAGING =
    ApiService.BASE_NODE_URL + "endorse/endorsement/paging";

  public static GET_ENDORSE_TAGS =
    ApiService.base_path + "Endorse/GetEndorseTags";
  public static GET_ENDORSE_TAGS_DETAILS =
    ApiService.BASE_NODE_URL + "endorse/endorse_tags/details";

  public static GET_SELECTED_ENDORSE_TAG_COUNT =
    ApiService.base_path + "Endorse/GetSelectedEndorseTagCount";
  public static GET_SELECTED_ENDORSE_TAGS =
    ApiService.BASE_NODE_URL + "endorse/endorse_tags/selected_endorse_tag_count";

  public static GET_LIKES_COUNT =
    ApiService.base_path + "ReviewAndRating/GetLikesCount";
  public static ADD_LIKES = ApiService.base_path + "ReviewAndRating/AddLikes";
  public static UPDATE_LIKES =
    ApiService.base_path + "ReviewAndRating/UpdateLikes";
  public static GET_USER_LIKE =
    ApiService.base_path + "ReviewAndRating/GetUserLike";

  // Image Upload in Business screen
  public static PREMIUM_MERCHANT_IMAGE_UPLOAD =
    cdn_portal + "ifile/api/File/UploadMerchantAssets";
  public static PREMIUM_MERCHANT_DOCUMENT_UPLOAD =
    cdn_portal + "ifile/api/File/KYCUpload";
  public static PREMIUM_MERCHANT_ASSETS =
    ApiService.BASE_NODE_URL + "premium_merchant/merchant_assets";
  public static DELETE_MERHANT_IMAGE =
    ApiService.BASE_NODE_URL +
    "premium_merchant/merchant/delete_merchant_image";
  public static PREMIUM_MERCHANT_ASSETS_DEFAULTIMG =
    ApiService.BASE_NODE_URL +
    "premium_merchant/merchant_assets/updateIsDefault";

  //ProppyCares
  public static GOPHERMAP_PREFIX = ApiService.base_path + "GopherMap/";
  public static GET_MERCHANT_LISTING =
    ApiService.GOPHERMAP_PREFIX + "GetMerchantByUserID";
  public static GET_MERCHANT_LISTING_BY_LISTINGID =
    ApiService.GOPHERMAP_PREFIX + "GetMerchantByListingID";
  public static GET_MERCHANT_UPDATE =
    ApiService.GOPHERMAP_PREFIX + "UpdateMerchant";
  public static GET_MERCHANT_ADDNEW =
    ApiService.GOPHERMAP_PREFIX + "AddMerchant";
  public static ADD_MERCHANT_TRANSFER =
    ApiService.GOPHERMAP_PREFIX + "AddMerchantTransfer";
  public static UPDATE_MERCHANT_TRANSFER_STATUS =
    ApiService.GOPHERMAP_PREFIX + "UpdateMerchantTransferStatus";
  //v2
  public static GET_MERCHANT_LISTING_BY_LISTINGID_V2 =
    ApiService.GOPHERMAP_PREFIX + "GetMerchantByListingID_V2";
  public static GET_MERCHANT_UPDATE_V2 =
    ApiService.GOPHERMAP_PREFIX + "UpdateMerchant_V3";
  public static GET_MERCHANT_ADDNEW_V2 =
    ApiService.GOPHERMAP_PREFIX + "AddMerchant_V3";
  public static GET_ProppyCares_By_Category_V2 =
    ApiService.GOPHERMAP_PREFIX + "getProppyCaresByCategoryV2";
  public static GET_ProppyCares_By_Category_Paging =
    ApiService.GOPHERMAP_PREFIX + "getProppyCaresByCategoryPaging";
  public static GET_MERCHANT_GROUPS =
    ApiService.GOPHERMAP_PREFIX + "GetMerchantGroups";
  public static GET_MERCHANT_PRECHECK =
    ApiService.GOPHERMAP_PREFIX + "GetMerchantPrecheck";
  public static ADD_MERCHANT_IMAGES =
    cdn_portal + "ifile/api/File/UploadProppyCareImages";
  public static REGISTER_MERCHANT_ACTIVATION_REQUEST =
    ApiService.GOPHERMAP_PREFIX + "RegisterMerchantActivationRequest";
  public static UPDATE_MERCHANT_IMAGE_DESC =
    ApiService.GOPHERMAP_PREFIX + "UpdateMerchantImageDesc";
  public static GET_MERCHANT_IMAGES =
    ApiService.GOPHERMAP_PREFIX + "GetMerchantImages";
  //End

  //ErrorLogging
  // public static ADD_ERROR_LOG = ApiService.base_path + "Logging/AddErrorLog";
  public static ADD_ERROR_LOG = ApiService.BASE_NODE_URL + "er_logs/error_logs";
  //End

  //Google Api
  public static GOOGLE_MAP_API_ACCESS_TOKEN =
    "AIzaSyAaQJpf2YbAitpQH0FFg-QT6yY9uSa0eHM";
  public static GOOGLE_MAP_AUTOCOMPLETE_API_URL =
    "https://maps.googleapis.com/maps/api/place/autocomplete/json?key=" +
    ApiService.GOOGLE_MAP_API_ACCESS_TOKEN +
    "&input=";
  public static GOOGLE_MAP_PLACE_DETAILS_API_URL =
    "https://maps.googleapis.com/maps/api/place/details/json?&key=" +
    ApiService.GOOGLE_MAP_API_ACCESS_TOKEN +
    "&place_id=";
  //End

  //Global search
  public static SEARCH_PROPERTY_MARKET_BY_SHORT_DESC_AND_ADDRESS =
    ApiService.PROPERTY_MARKET_PREFIX +
    "GetPropertyMarketByAddressAndShortDesc";
  public static SEARCH_MERCHENTS_BY_DESC_AND_ADDRESS =
    ApiService.PROPPY_CARES_PREFIX + "GetProppyCaresByAddressAndDescription";
  //End

  //Notifications
  public static SET_USER_FCMTOKEN =
    ApiService.base_path + "Notification/SetUserFCMToken";
  public static REMOVE_USER_FCMTOKEN =
    ApiService.base_path + "Notification/RemoveUserFCMToken";
  public static PUSH_NOTIFICATION =
    ApiService.base_path + "Notification/notify";
  //End

  //SocialAuth
  public static SOCIAL_AUTH_USER_VALIDATION_AND_REGISTRATION =
    ApiService.base_path + "SocialAuth/ValidateUserAndRegister";
  public static SOCIAL_AUTH_UPDATE_MEMBER_MOBILE =
    ApiService.base_path + "SocialAuth/UpdateMemberMobile";
  public static SOCIAL_AUTH_UPDATE_MEMBER_STATUS =
    ApiService.base_path + "SocialAuth/UpdateMemberStatus";
  public static SOCIAL_NETWORK_USER_AUTHENTICATION = ApiService.BASE_NODE_URL + 'auth/social_login';

  //END

  //report
  public static CREATE_REPORT =
    ApiService.base_path + "Report/CreateReportListing";
  public static CREATE_REPORT_REASON =
    ApiService.BASE_NODE_URL + "report/report_reason";

  public static GET_REPORT_REASONS =
    ApiService.base_path + "Report/GetReportReasons";
  public static GET_REPORT_REASONS_DETAILS =
    ApiService.BASE_NODE_URL + "report/report_reason/details";

  public static GET_REPORTED_LISTING_BY_OBJECT_TYPE =
    ApiService.base_path + "Report/GetReportedListingByObjectType";
  public static GET_REPORT_REASON_PAGING =
    ApiService.BASE_NODE_URL + "report/report_reason/paging";

  public static UPDATE_REPORT_LISTING_STATUS =
    ApiService.base_path + "Report/UpdateReportListingStatus";
  public static UPDATE_REPORT_REASON_LISTING_STATUS =
    ApiService.BASE_NODE_URL + "report/report_reason/update_report_listing_status";

  //END

  //c360 starts
  public static GET_C360_LISTING = ApiService.base_path + "c360/GetC360Listing";
  public static GET_C360_LISTING_PAGING =
    ApiService.base_path + "c360/GetC360ListingPaging";
  public static GET_ALL_C360_LISTINGS =
    ApiService.base_path + "c360/GetAllC360Listings";
  public static GET_C360_LISTING_BY_LISTINGID =
    ApiService.base_path + "c360/GetC360ListingByListingID";
  public static CREATE_C360_LISTING =
    ApiService.base_path + "c360/CreateC360Listing";
  public static ADD_C360_LISTING_IMAGES =
    ApiService.base_path + "c360/AddC360ListingImageFile";
  public static ADD_C360_NEEDS_DOCUMENTS =
    ApiService.base_path + "c360/AddC360NeedsDocument";
  public static GET_C360_NEEDS_DOCUMENTS =
    ApiService.base_path + "c360/GetC360NeedsDocument";
  public static GET_C360_LISTING_BY_CREATEDBY =
    ApiService.base_path + "c360/GetC360ListingByCreatedBy";
  public static UPDATE_C360_LISTING =
    ApiService.base_path + "c360/UpdateC360Listing";
  public static C360_LISTING_EXTEND =
    ApiService.base_path + "c360/ExtendC360Listing";
  public static UPDATE_C360_LISTING_STATUS =
    ApiService.base_path + "c360/UpdateC360ListingStatus";
  public static C360_ADD_ENDORSE = ApiService.base_path + "c360/C360AddEndorse";
  public static C360_IS_MODERATOR =
    ApiService.base_path + "c360/C360IsModerator";
  public static C360_IS_ADMIN = ApiService.base_path + "c360/C360IsAdmin";
  public static ADD_C360_LISTING_IMAGES_FOR_NEEDS =
    ApiService.base_path + "c360/AddC360ListingImageForNeeds";
  public static UPDATE_C360_LISTING_IMAGES_FOR_SUPPLIES =
    ApiService.base_path + "c360/UpdateC360ListingImageSupplies";
  public static DELETE_C360_LISTING_IMAGES =
    ApiService.base_path + "c360/DeleteC360ListingImage";
  public static UPDATE_C360_LISTING_RED_FLAG =
    ApiService.base_path + "c360/UpdateC360ListingRedFlag";
  public static ADD_C360_LISTING_RED_FLAG =
    ApiService.base_path + "c360/AddC360ListingRedFlag";
  public static ADD_C360_LISTING_ADMIN =
    ApiService.base_path + "c360/AddC360ListingAdmins";
  public static DELETE_C360_LISTING_ADMIN =
    ApiService.base_path + "c360/DeleteC360ListingAdmin";
  public static GET_C360_RED_FLAG_LISTINGS =
    ApiService.base_path + "c360/GetC360RedFlagedListing";
  public static DELETE_LISTING_HASH_TAG =
    ApiService.base_path + "c360/DeleteListingHashTag";
  public static CREATE_C360_VACCINE_BOOKING =
    ApiService.base_path + "c360/CreateC360VaccineBooking";
  public static UPDATE_C360_VACCINE_BOOKING =
    ApiService.base_path + "c360/UpdateC360VaccineBooking";
  public static GET_C360_VACCINE_BOOKING_PAGING =
    ApiService.base_path + "c360/GetC360VaccineBookingsPaging";
  public static GET_C360_VACCINE_BOOKING_BY_BOOKINGID =
    ApiService.base_path + "c360/GetC360VaccineBookingByBookingID";
  public static GET_C360_LISTING_ADMINS =
    ApiService.base_path + "c360/GETC360ListingAdmins";
  public static ADD_C360_LISTING_ADMINS =
    ApiService.base_path + "c360/AddC360ListingAdmins";
  public static REMOVE_C360_LISTING_ADMINS =
    ApiService.base_path + "c360/DeleteC360ListingAdmin";

  public static SEARCH_C360_LISTINGS =
    ApiService.base_path + "c360/SearchC360Listings";

  public static ADD_C360_PROJECT_SUPPORTORS =
    ApiService.base_path + "c360/AddC360ProjectSupporters";
  public static DELETE_C360_PROJECT_SUPPORTORS =
    ApiService.base_path + "c360/DeleteC360ProjectSupporters";
  public static GET_C360_PROJECT_SUPPORTORS =
    ApiService.base_path + "c360/GetC360ProjectSupporters";
  public static PROPPYDOC_MODULE_ID = 1;
  public static PROPPY_AGENT_MODULE_ID = 2;

  public static GET_MODULES = ApiService.BASE_NODE_URL + "work_flow/pro_modules/details"
  public static GET_MODULE_ROLES = ApiService.BASE_NODE_URL + "work_flow/pro_module_roles/details";
  public static GET_PRO_MODULE_ACTIONS = ApiService.BASE_NODE_URL + "work_flow/pro_module_actions/details"
  public static ADD_WORK_FLOW_STEPS = ApiService.BASE_NODE_URL + "work_flow/work_flow_steps";
  public static GET_WORK_FLOW_STEPS = ApiService.BASE_NODE_URL + "work_flow/work_flow_steps/details";
  public static DELETE_WORK_FLOW_STEPS = ApiService.BASE_NODE_URL + "work_flow/work_flow_steps/";
  public static UPDATE_WORK_FLOW_STEPS = ApiService.BASE_NODE_URL + "work_flow/work_flow_steps";
  public static UPDATE_SIGNING_ORDER = ApiService.BASE_NODE_URL + "work_flow/work_flow_steps/updateSeq";

  public static GET_MERCHANT_DELIVERY_PLATFORM_PROVIDERS = ApiService.BASE_NODE_URL + "premium_merchant/merchant_delivery_platform_providers/details";
  public static GET_MERCHANT_PAYMENT_GATEWAY_PROVIDERS = ApiService.BASE_NODE_URL + "premium_merchant/merchant_payment_gateway/details";
  public static GET_MERCHANT_PG_PARAMETERS = ApiService.BASE_NODE_URL + "premium_merchant/merchant_pg_parameters";
  public static GET_DELIVERY_PARTNER = ApiService.BASE_NODE_URL + "external_call";

  public static ADD_RESIDENT_REGISTRATION = ApiService.BASE_NODE_URL + "auth/ph_resident_registration"
  public static GET_PREFERRED_COURIER_SERVICE_DETAILS = ApiService.BASE_NODE_URL + "premium_merchant/merchant_preferred_courier_service/details"

  public static C360_RED_FLAG_REASONS = [
    "Spam/ Fake location",
    "Not available anymore",
    "Information mismatched",
    "Impersonating someone else",
    "Others (Please comment)",
  ];
  public static C360_NEED_TYPES = [
    {
      name: "Food",
      id: 1,
      img: "https://proppyapp.com/images/c360/need_1.png",
    },
    {
      name: "Beverages",
      id: 2,
      img: "https://proppyapp.com/images/c360/need_2.png",
    },
    {
      name: "Fruits",
      id: 3,
      img: "https://proppyapp.com/images/c360/need_3.png",
    },
    {
      name: "Household Items ",
      id: 4,
      img: "https://proppyapp.com/images/c360/need_4.png",
    },
    {
      name: "Medicine",
      id: 5,
      img: "https://proppyapp.com/images/c360/need_5.png",
    },
    {
      name: "Others",
      id: 0,
      img: "https://proppyapp.com/images/c360/need_0.png",
    },
  ];
  public static C360_NEED_TYPES_IMAGES = [
    "https://proppyapp.com/images/c360/need_0.png",
    "https://proppyapp.com/images/c360/need_1.png",
    "https://proppyapp.com/images/c360/need_2.png",
    "https://proppyapp.com/images/c360/need_3.png",
    "https://proppyapp.com/images/c360/need_4.png",
    "https://proppyapp.com/images/c360/need_5.png",
  ];
  public static C360_NEED_TYPES_NAMES = [
    "Others",
    "Food",
    "Beverages",
    "Fruits",
    "Household Items",
    "Medicine",
  ];
  //ends

  //OwnProperty Start
  public static ADD_OWN_PROPERTY =
    ApiService.base_path + "OwnProperty/AddOwnProperty";
  public static ADD_OWN_PROPERTY_IMAGES =
    ApiService.base_path + "OwnProperty/AddOwnPropertyImages";
  public static DELETE_OWN_PROPERTY_IMAGE =
    ApiService.base_path + "OwnProperty/DeleteOwnPropertyImage";
  public static UPDATE_OWN_PROPERTY =
    ApiService.base_path + "OwnProperty/UpdateOwnProperty";
  public static GET_OWN_PROPERTIES =
    ApiService.base_path + "OwnProperty/GetOwnProperties";
  public static GET_OWN_PROPERTY_BY_LISTINGID =
    ApiService.base_path + "OwnProperty/GetOwnPropertyByListingID";
  public static GET_OWN_PROPERTY_IMAGES =
    ApiService.base_path + "OwnProperty/GetOwnPropertyImages";
  public static UPDATE_OWN_PROPERTY_STATUS =
    ApiService.base_path + "OwnProperty/UpdateOwnPropertyStatus";
  public static PUBLISH_PROPERTY_TO_MARKETPLACE =
    ApiService.base_path + "OwnProperty/PublishOwnPropertyToMP";
  //ends

  // ap service
  public static GET_PROPERTY_PAGING = ApiService.BASE_P360_NODE_API_URL + 'ap/paging';

  // Start
  public static GET_USER_VOUCHERS =
    ApiService.base_path + "Vouchers/GetUserVouchers";
  public static GET_USER_VOUCHERS_BY_ID =
    ApiService.base_path + "Vouchers/GetUserVouchersByID";
  public static ADD_VOUCHER_TRANSFER =
    ApiService.base_path + "Vouchers/AddVoucherTransfer";
  public static GET_USER_VOUCHERS_TRANSACTIONS =
    ApiService.base_path + "Vouchers/GetVoucherTransactions";
  public static GET_USER_POINTS_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/product/details";
  public static GET_MARCHANT_PRDUCT_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/cust_loyality_mst/details";
  public static GET_MARCHANT_POINTS_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/cust_loyality_trx";
  public static GET_TRANSACTION_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/cust_loyality_trx/details";
  //ends

  // start
  public static GET_PRELOVED_LISTING_DETAILS =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_listing/details";
  public static GET_PRELOVED_LISTING_DELETE =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_listing/";
  public static GET_PRELOVED_LISTING_PAGING =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_listing/paging";
  public static UPDATE_DEFAULT_IMAGE =
    ApiService.BASE_NODE_URL +
    "pre_loved/pre_loved_listing/update_image_default";
  public static GET_PRELOVED_DEAL_METHODS =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_del_type/details";
  public static GET_PRELOVED_SEARCH_FILTER_DATA_WRTO_PID =
    ApiService.BASE_NODE_URL +
    "pre_loved/pre_loved_categories_favourites/details";
  public static ADD_PRELOVED_SEARCH_FILTER_DATA_WRTO_PID =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_categories_favourites";
  public static DELETE_PRELOVED_SEARCH_FILTER_DATA_WRTO_PID =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_categories_favourites";
  public static PRE_LOVED_DEALS_INBOX =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_inbox/details";
  public static PRE_LOVED_DEALS_INBOX_PAGING =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_inbox/paging";
  public static PRE_LOVED_DEALS_STATUS_UPDATE =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_inbox";
  public static PRE_LOVED_DEALS_TRACKER_DATA =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_deals/details";
  public static PRE_LOVED_DEALS_TRACKER_PAGING =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_deals/paging";
  // end

  ////MERCHNAT QUEUE
  public static ADD_UPDATE_QUEUE =
    ApiService.BASE_NODE_URL + "premium_merchant/merchant_queue";
  public static QUEUE_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/merchant_queue/details";
  // proppy academy Start
  public static GET_ACADEMY_CATEGORIES =
    ApiService.base_path + "ProppyAcademy/GetAcademyCategories";
  public static GET_ACADEMY_CONTENT =
    ApiService.base_path + "ProppyAcademy/GetAcademyContent";
  public static GET_ACADEMY_MEDIA_CONTENT =
    ApiService.base_path + "ProppyAcademy/GetAcademyMedia";
  // ends

  //contact list
  public static GET_USER_CONTACT_LIST =
    ApiService.base_path + "ContactList/GetContactsByProppyUserID";
  public static DELETE_USER_CONTACT_LIST =
    ApiService.base_path + "ContactList/DeleteContactList";
  public static ADD_USER_CONTACT_LIST =
    ApiService.base_path + "ContactList/AddContactList";
  //end

  //Business
  public static GET_OTHER_BUSINESS =
    ApiService.base_path + "Business/GetOtherBusinesses";
  public static GET_OTHER_BUSINESS_PAGING =
    ApiService.base_path + "Business/GetOtherBusinessesPaging";
  public static GET_OWN_BUSINESS =
    ApiService.base_path + "Business/GetOwnBusinesses";

  public static GET_MEMBER_PAYMENT_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/member_payments/details";
  //end

  //weather
  public static GET_WEATHER_BY_LATLONG_URL =
    "https://api.openweathermap.org/data/2.5/find?";
  public static WEATHER_API_KEY = "47c4b4d3c749edd6b61569ca096615b6";
  //end
  //contact list node services
  public static GET_CONTACT_LIST = ApiService.BASE_NODE_URL + "proppy_contact_list/contact_list/details_v2";
  public static ADD_CONTACT_LIST = ApiService.BASE_NODE_URL + "proppy_contact_list/contact_list";
  public static UPDATE_CONTACT_LIST = ApiService.BASE_NODE_URL + "proppy_contact_list/contact_list/update_status";
  // public static EXPORT_CONTACT_LIST = "http://localhost:3001/export2vcard";
  // public static EXPORT_CONTACT_LIST = "http://localhost:3001/vcard/vcard/details";

  public static GET_CONTACT_GROUP_LIST = ApiService.BASE_NODE_URL + "contacts/contact_book_group/details";
  public static ADD_NEW_CONTACT_GROUP = ApiService.BASE_NODE_URL + "contacts/contact_book_group";
  public static UPDATE_CONTACT_GROUP =  ApiService.BASE_NODE_URL + "contacts/contact_book_group";
  public static ADD_UPDATE_CONTACT_GROUP_MEMBER =  ApiService.BASE_NODE_URL + "contacts/contact_book_group_member";
  public static UPDATE_REJECTED_CONTACT_STATUS = ApiService.BASE_NODE_URL + 'proppy_contact_list/contact_list/rejection_status_update'

  //Global datahandler services
  public static GET_GLOBAL_DATA_HANDLER_DETAILS = ApiService.BASE_NODE_URL + "common_data_handler/details";
  public static UPDATE_BADGES_EYE_GLOBAL_STATUS = ApiService.BASE_NODE_URL + "common_data_handler";

  //proppynews
  public static PROPPY_NEWS_GET_TAGCATEGORYS = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_tags_category/details"
  public static PROPPY_NEWS_GET_BYSTATUS = ApiService.BASE_NODE_URL + "proppy_news/proppy_news/details"
  public static PROPPY_NEWS_GET_MODERATORS_COUNT = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_moderator/moderators_count"
  public static PROPPY_NEWS_MODERATOR_ADD_TAGS = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_tags"
  public static PROPPY_NEWS_MODERATOR_DELETE_TAGS = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_tags/"
  public static PROPPY_NEWS_DELETE_USERSUB = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_submission/delete"
  // public static PROPPY_NEWS_GET_TAGCATEGORYS =
  //   ApiService.base_path + "ProppyNews/GetAllTagCategory";
  // public static PROPPY_NEWS_GET_BYSTATUS =
  //   ApiService.base_path + "ProppyNews/GetAllProppyNewsByStatus";
  // public static PROPPY_NEWS_GET_MODERATORS_COUNT =
  //   ApiService.base_path + "ProppyNews/GetAllProppyNewsModeratorsCount";
  // public static PROPPY_NEWS_MODERATOR_ADD_TAGS =
  //   ApiService.base_path + "ProppyNews/AddModeratorProppyNewsTags";
  // public static PROPPY_NEWS_MODERATOR_DELETE_TAGS =
  //   ApiService.base_path + "ProppyNews/DeleteModeratorProppyNewsTags";
  // public static PROPPY_NEWS_DELETE_USERSUB =
  //   ApiService.base_path + "ProppyNews/DeleteProppyNewsSubmission";
  public static PROPPY_NEWS_GET_USERSUB =
    ApiService.base_path + "ProppyNews/GetAllProppyNewsUserSubmission";
  public static PROPPY_NEWS_ADD_USERSUB =
    ApiService.base_path + "ProppyNews/AddProppyNewsUserSubmission";
  public static PROPPY_NEWS_GET_FORDISPLAY =
    ApiService.base_path + "ProppyNews/GetAllProppyNewsForDisplay";
  public static PROPPY_NEWS_GET_FORDISPLAY_WITHTAGS =
    ApiService.base_path + "ProppyNews/GetAllProppyNewsForDisplayWithTags";
  public static PROPPY_NEWS_GET_FORMODERATOR =
    ApiService.base_path + "ProppyNews/GetAllProppyNewsForModerator";
  public static PROPPY_NEWS_ADD_FORDISPLAY_COUNT =
    ApiService.base_path + "ProppyNews/GetAllProppyNewsForDisplayCount";
  public static PROPPY_NEWS_MODERATOR_ADD_USERSUB =
    ApiService.base_path + "ProppyNews/AddModeratorProppyNewsUserSubmission";
  public static PROPPY_NEWS_MODERATOR_UPDATE_USERSUB =
    ApiService.base_path + "ProppyNews/UpdateModeratorProppyNewsUserSubmission";
  public static PROPPY_NEWS_GET_FORDISPLAY_V2 =
    ApiService.base_path + "ProppyNews/GetAllProppyNewsForDisplayV2";
  public static PROPPY_NEWS_GET_FORDISPLAY_WITHTAGS_V2 =
    ApiService.base_path + "ProppyNews/GetAllProppyNewsForDisplayWithTagsV2";
  public static PROPPY_NEWS_GET_URL_FORSHARE =
    ApiService.base_path + "ProppyNews/GetAllProppyNewsURLForShare";

  //v2
  public static PROPPY_NEWS_USER_SUBMISSION = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_submission";
  public static GET_PROPPY_NEWS_USER_LATEST_SUBMISSION = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_submission/details"
  public static PROPPY_NEWS_GET_FORDISPLAY_AND_WITHTAGS_PAGING = ApiService.BASE_NODE_URL + "proppy_news/proppy_news/paging";
  public static GET_PROPPY_NEWS_ACTIVE_BATCH_BY_OWNER_PID = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_moderator/details"
  public static GET_PROPPY_NEWS_OPEN_BATCHES = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_moderator/details";
  public static ADD_PROPPY_NEWS_BATCH_OWNER = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_moderator/add_batch_owner"
  public static GET_PROPPY_NEWS_BATCH_DETAILS_BY_BATCHID = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_moderator_batch/details"
  public static TRANSFER_PROPPY_NEWS_BATCH = ApiService.BASE_NODE_URL + "proppy_news/proppy_news_moderator/transfer_proppy_news_batch"
  public static UPDATE_PROPPY_NEWS_SUBMISSION_STATUS =
    ApiService.BASE_NODE_URL + "proppy_news/proppy_news_submission/update_status";
  // public static PROPPY_NEWS_USER_SUBMISSION =
  //   ApiService.base_path + "ProppyNews/ProppyNewsUserSubmission";
  // public static GET_PROPPY_NEWS_USER_LATEST_SUBMISSION =
  //   ApiService.base_path + "ProppyNews/GetProppyNewsUserLatestSubmission";
  // public static PROPPY_NEWS_GET_FORDISPLAY_PAGING =
  //   ApiService.base_path + "ProppyNews/GetAllProppyNewsForDisplayPaging";
  // public static PROPPY_NEWS_GET_FORDISPLAY_WITHTAGS_PAGING =
  //   ApiService.base_path + "ProppyNews/GetAllProppyNewsForDisplayWithTagsPaging";
  // public static GET_PROPPY_NEWS_ACTIVE_BATCH_BY_OWNER_PID =
  //   ApiService.base_path + "ProppyNews/GetProppyNewsActiveBatchByOwnerPID";
  // public static ADD_PROPPY_NEWS_BATCH_OWNER =
  //   ApiService.base_path + "ProppyNews/AddProppyNewsBatchOwner";
  // public static GET_PROPPY_NEWS_BATCH_DETAILS_BY_BATCHID =
  //   ApiService.base_path + "ProppyNews/GetProppyNewsBatchDetailsByBatchID";
  // public static GET_PROPPY_NEWS_OPEN_BATCHES =
  //   ApiService.base_path + "ProppyNews/GetProppyNewsOpenBatches";
  // public static UPDATE_PROPPY_NEWS_SUBMISSION_STATUS =
  //   ApiService.base_path + "ProppyNews/UpdateProppyNewsSubmissionStatus";
  // public static TRANSFER_PROPPY_NEWS_BATCH =
  //   ApiService.base_path + "ProppyNews/TransferProppyNewsBatch";
  //end

  //needs
  public static PROPPYCARE_NEEDS_PREFIX =
    ApiService.base_path + "ProppyCareNeeds/";
  public static SAVE_PROPPYCARE_NEEDS =
    ApiService.PROPPYCARE_NEEDS_PREFIX + "SaveProppyCareNeedsV2";
  public static UPLOAD_NEED_IMAGES =
    cdn_portal + "ifile/api/File/UploadNeedImages";
  public static UploadMemberProfilePic =
    cdn_portal + "ifile/api/File/UploadMemberProfilePic";
  public static GET_PROPPYCARE_NEEDS =
    ApiService.PROPPYCARE_NEEDS_PREFIX + "GetProppyCareNeeds";
  public static GET_PROPPYCARE_NEEDS_PAGING =
    ApiService.PROPPYCARE_NEEDS_PREFIX + "GetProppyCareNeedsPagingV2";
  public static GET_PROPPYCARE_NEEDS_IMAGES =
    ApiService.PROPPYCARE_NEEDS_PREFIX + "GetProppyCareNeedImages";
  public static UPDATE_PROPPYCARE_NEED_STATUS =
    ApiService.PROPPYCARE_NEEDS_PREFIX + "UpdateProppyCareNeedStatus";
  public static GET_PROPPYCARE_NEED_BY_NEEDSID =
    ApiService.PROPPYCARE_NEEDS_PREFIX + "GetProppyCareNeedByNeedsIDV2";
  public static ADD_PROPPYCARE_NEEDS_GRAB =
    ApiService.PROPPYCARE_NEEDS_PREFIX + "RecordProppyCareNeedsGrab";
  public static UPDATE_PROPPYCARE_NEEDS_GRAB =
    ApiService.PROPPYCARE_NEEDS_PREFIX + "UpdateProppyCareNeedGrabStatus";
  public static PROPPYCARE_NEEDS_UPDATE_COCREATORID =
    ApiService.base_path + "ProppyCareNeeds/UpdateCoCreatorID";
  public static PROPPYCARE_NEEDS_UPDATE_EXPIREDATETIME =
    ApiService.base_path + "ProppyCareNeeds/UpdateExpireDateTime";
  // ProppyCareNeeds/UpdateCoCreatorID
  //end

  // needs node services

  public static ADD_PROPPY_CARE_NEEDS =
    ApiService.BASE_NODE_URL + "needs/proppy_cares_needs";
  public static GET_PROPPY_CARE_NEEDS =
    ApiService.BASE_NODE_URL + "needs/proppy_cares_needs/details";
  public static GET_PROPPY_CARE_NEEDSPAGING =
    ApiService.BASE_NODE_URL + "needs/proppy_cares_needs/paging";
  public static GET_PROPPY_CARE_MAXBIDCOUNT =
    ApiService.BASE_NODE_URL + "needs/proppy_cares_needs/maxBidCount";

  //proppy deal
  public static PROPPY_DEALS_PREFIX = ApiService.base_path + "ProppyDeals/";
  public static GET_PROPPY_DEALS_INBOX =
    ApiService.PROPPY_DEALS_PREFIX + "GetInbox";
  public static GET_PROPPY_MY_DEALS =
    ApiService.PROPPY_DEALS_PREFIX + "GetMyDeals";
  public static GET_PROPPY_DEALS_INBOX_PAGING =
    ApiService.PROPPY_DEALS_PREFIX + "GetInboxPagingV2";
  public static GET_PROPPY_MY_DEALS_PAGING =
    ApiService.PROPPY_DEALS_PREFIX + "GetMyDealsPagingV2";
  public static GET_PROPPY_MY_DEALS_BY_ORDERID =
    ApiService.PROPPY_DEALS_PREFIX + "GetMyDealsByOrderIDV2";
  public static GENERATE_ORDER_RECEIPT = ApiService.BASE_NODE_URL + "pdf/mr";

  public static CREATE_ORDER = ApiService.PROPPY_DEALS_PREFIX + "CreateOrderV2";
  public static UPDATE_ORDER = ApiService.PROPPY_DEALS_PREFIX + "UpdateOrderV2";
  public static GET_ORDER_BY_ORDERID =
    ApiService.PROPPY_DEALS_PREFIX + "GetOrderByOrderID";
  public static UPDATE_ORDER_STATUS =
    ApiService.PROPPY_DEALS_PREFIX + "UpdateOrderStatusV2";
  public static UPDATE_ORDER_HISTORY_CONFIRMATION =
    ApiService.PROPPY_DEALS_PREFIX + "UpdateOrderHistoryConfirmation";
  public static GET_PROPPY_DEALS_AGENTS_INBOX_PAGING =
    ApiService.PROPPY_DEALS_PREFIX + "GetAgentInboxPaging";
  public static GET_PROPPY_DEALS_AGENTS_DEALS_PAGING =
    ApiService.PROPPY_DEALS_PREFIX + "GetAgentDealsPaging";
  public static GET_PROPPY_DEALS_MAO_INBOX_PAGING =
    ApiService.PROPPY_DEALS_PREFIX + "GetMAOInboxPaging";
  public static GET_PROPPY_DEALS_MAO_DEALS_PAGING =
    ApiService.PROPPY_DEALS_PREFIX + "GetMAODealsPaging";

  // OTP Services
  public static GENERATE_OTP_URL =
    ApiService.base_path + "ProLoginController/GenerateOtp";
  public static VERIFY_OTP_URL =
    ApiService.base_path + "ProLoginController/VerifyOtp";
  public static ENTER_OR_EXIT_URL =
    ApiService.base_path + "TalkJS/EnterOrExitFromGroup";
  public static CHAT_SEND_MSG_URL = ApiService.base_path + "TalkJS/SendMessage";
  public static VALIDATE_PASSWORD =
    ApiService.base_path + "ProLoginController/ValidatePassword";
  //end

  // OTP Node Services
  public static GENERATE_OTP =
    ApiService.BASE_NODE_URL + "kyc_levels/kyc_otp_auth/gen_otp";
  public static VERIFY_OTP =
    ApiService.BASE_NODE_URL + "kyc_levels/kyc_otp_auth/verify_otp";
  public static GET_EKYC_lEVELS_DETAILS =
    ApiService.BASE_NODE_URL + "kyc_levels/kyc_levels_trx/details";
  public static ADD_EKYC_LEVEL_DETAILS =
    ApiService.BASE_NODE_URL + "kyc_levels/kyc_levels_trx";
  // END

  //WishList Api's
  public static CREATE_WISHLIST =
    ApiService.base_path + "proppywishlist/create-wishlist";
  public static UPDATE_WISHLIST =
    ApiService.base_path + "proppywishlist/update-wishlist";
  public static GET_USER_WISHLISTS =
    ApiService.base_path + "proppywishlist/get-user-wishlist";
  public static GET_WISHLIST_DETALS =
    ApiService.base_path + "proppywishlist/get-wishlist-detail";
  public static DELETE_WISHLIST =
    ApiService.base_path + "proppywishlist/delete-wishlist";
  public static GET_WISHLIST_BY_ID =
    ApiService.base_path + "proppywishlist/GetWishlistByID";
  public static GET_WISHLIST_INBOX =
    ApiService.base_path + "proppywishlist/WishlistInbox";
  public static GET_WISHLIST_AGENT_SUGGESTIONS =
    ApiService.base_path + "proppywishlist/AddWishlistAgentSuggetions";
  public static DELETE_WISHLIST_AGENT_SUGGESTIONS =
    ApiService.base_path + "proppywishlist/DeleteWishlistAgentSuggetions";
  public static GET_AGENT_PROPERTIES_BY_WISHLIST_FILTER =
    ApiService.base_path + "proppywishlist/GetAgentPropertiesByWishListFilter";
  public static GET_WISHLIST_BY_FILTER =
    ApiService.base_path + "proppywishlist/GetWishListByFilter";
  //end

  // Wishlist Node Api
  public static GET_BB_WISHLIST_DETAILS =
    ApiService.BASE_NODE_URL + "bulletin_board/wishlist/details";

  // Wishlist Python Api
  public static GET_WISH_LIST_MATCHED_RESULTS = ApiService.basePythonApiUrl + 'API/WishlistPreCheck'

  //end

  // Customer INBOX START
  public static GET_CUSTOMER_BUSINESS_INBOX =
    ApiService.BASE_NODE_URL + "premium_merchant/user_inbox/paging";
  public static GET_CUSTOMER_BUSINESS_INBOX_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/user_inbox/getInboxDetails";
  public static GET_CUSTOMER_BUSINESS_INBOX_PAGING =
    ApiService.BASE_NODE_URL + "premium_merchant/user_inbox/getInboxPaging";
  // Merchant INBOX END

  // Merchant Deals START
  public static GET_MERCHANT_DEALS_DATA_PAGING =
    ApiService.BASE_NODE_URL + "premium_merchant/order_hdr/paging";
  public static GET_CUSTOMER_ORDERS_COUNT =
    ApiService.BASE_NODE_URL + "premium_merchant/order_hdr/listing_counts";
  public static GET_MERCHANT_DEALS_DATA =
    ApiService.BASE_NODE_URL + "premium_merchant/order_hdr/details";
  public static ADD_TRACKER_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/pmp_order_history";
  public static GET_MERCHANT_ORDER_HISTORY =
    ApiService.BASE_NODE_URL + "premium_merchant/order_hdr/order_history";

  // Merchant Deals END

  //MOP Api's
  public static CALCULATE_MOP = ApiService.base_path + "ProMOP/CalcMortgage";
  public static GET_MOP = ApiService.base_path + "ProMOP/GetMemberProperties";
  public static UPDATE_PAYMENT_SCH =
    ApiService.base_path + "ProMOP/UpdatePaymentSch";
  public static GET_DATA_BY_OBJECT_ID =
    ApiService.base_path + "ProMOP/GetDataByObjectID";
  public static ADD_DATA_MEMBER_PROPERTY =
    ApiService.base_path + "ProMOP/AddMemberProperty";
  public static UPDATE_DATA_MEMBER_PROPERTY =
    ApiService.base_path + "ProMOP/UpdateMemberProperty";
  public static GET_MOP_SCH_BY_ID =
    ApiService.base_path + "ProMOP/GetMemberPropertyPaymentSchByID";
  public static UPDATE_SUBSCRIPTION_STATUS =
    ApiService.base_path + "ProMOP/UpdateSubscriptionStatus";
  public static GET_PAYMENT_NOTIFICATIONS =
    ApiService.base_path + "ProMOP/GetMemberPropertyPaymentNotifications";
  //end

  // Developers Start
  public static GET_DEVELOPERS =
    ApiService.BASE_NODE_URL + "property_developer/developer/details";
  public static GET_DEVELOPER_PROPERTIES =
    ApiService.BASE_NODE_URL +
    "property_developer/proppy_property_market_filter/details";
  public static GET_DEVELOPER_PROPERTIES_BY_PAGING =
    ApiService.BASE_NODE_URL +
    "property_developer/proppy_property_market_filter/paging";
  public static GET_DEVELOPER_PROPERTIES_BY_DETAILS =
    ApiService.BASE_NODE_URL +
    "property_developer/proppy_property_market_filter/details";
  // Developers End

  //ProAgency Api's
  public static GET_AGENCY = ApiService.base_path + "ProAgency/GetAgency";
  public static GET_AGENCY_AGENTS =
    ApiService.base_path + "ProAgency/GetAgencyAgents";
  //end

  //Facilities Management
  public static GET_PROPERTY_FACILITY_TYPES =
    ApiService.BASE_NODE_URL +
    "ph_facility_management/ph_property_facility_types/details";
  public static GET_PROPERTY_FACILITY_TIMINGS =
    ApiService.BASE_NODE_URL +
    "ph_facility_management/ph_property_facility_time_slot/details";
  public static GET_SELECTED_FACILITIES =
    ApiService.BASE_NODE_URL +
    "ph_facility_management/ph_property_facility/details";
  public static GET_SELECTED_FACILITIES_DETAILS =
    ApiService.BASE_NODE_URL +
    "ph_facility_management/ph_property_facility_details/details";
  public static GET_FACILITIES_SLOT_TIMINGS =
    ApiService.BASE_NODE_URL +
    "ph_facility_management/ph_property_facility_time_slot/details";
  public static GET_SELECTED_FACILITIES_DATA =
    ApiService.BASE_NODE_URL + "ph_facility_management/ph_fd/details";
  public static ADD_FACILITIES_BOOKING_DATA =
    ApiService.BASE_NODE_URL +
    "ph_facility_management/ph_property_facility_booking";
  public static GET_FACILITIES_BOOKING_DATA =
    ApiService.BASE_NODE_URL +
    "ph_facility_management/ph_property_facility_booking/details";
  //End

  //Incidents Report
  public static GET_INCIDENT_TYPES =
    ApiService.BASE_NODE_URL +
    "ph_incident_management/ph_incident_types/details";
  public static GET_INCIDENT_REPORT =
    ApiService.BASE_NODE_URL + "ph_incident_management/ph_report/details";
  public static GET_ALL_COMPLETED_INCIDENT_REPORTS =
    ApiService.BASE_NODE_URL + "ph_incident_management/ph_id/details";
  public static CLEAR_COMPLETED_INCIDENT_REPORTS =
    ApiService.BASE_NODE_URL + "ph_incident_management/ph_id/clear";
  public static ADD_INCIDENT_REPORT =
    ApiService.BASE_NODE_URL + "ph_incident_management/ph_report";
  public static UPLOAD_INCIDENT_REPORT_IMAGES =
    cdn_portal + "ifile/api/File/UploadIncidentReportImage";
  public static UPLOAD_PROPPY_ACADEMY_INFOGRAPHIC =
    cdn_portal + "ifile/api/File/UploadProppyAcademyImages";
  //End

  // Proppy Homes Api's
  public static GET_PROPERTY_VISITORY_LOG_DETAILS = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_log/details"
  public static UPDATE_PROPERTY_VISITOR_LOG = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_log/clear"
  public static DELETE_PROPERTY_VISITOR_LOG = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_log"
  public static ADD_PROPERTY_VISITOR_FAVORITIES = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_favorities"
  public static UPDATE_PROPERTY_VISITOR_FAVORITIES = ApiService.ADD_PROPERTY_VISITOR_FAVORITIES + "/multiUpdate"
  public static DELETE_PROPERTY_VISITOR_FAVORITIES = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_favorities"
  public static GET_PROPERTY_VISITOR_FAVORITIES_DETAILS = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_favorities/details"
  public static ADD_PROPERTY_VISITOR_REQUEST = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_request"
  public static GET_PROPERTY_EVENTS = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_request/details"
  public static GET_PROPERTY_EVENTS_DETAILS = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_vd/details"
  public static UPDATE_PROPERTY_EVENTS = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_request/clear"
  public static GET_PAST_VISITOR_LOGS = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_log/details"
  public static GET_PAST_VISITOR_FAVORITES = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_favorities/details"
  public static UPDATE_INDIVIDUAL_PROPERTY_VISITOR = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_log"
  public static UPDATE_INDIVIDUAL_PROPERTY_EVENT = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_request"
  public static ADD_VISITOR_PH_VEHICLE = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_vehicle"
  public static GET_VISITOR_PH_VEHICLE = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_vehicle/details"
  public static ADD_PH_PROPERTY_USER = ApiService.BASE_NODE_URL + "ph_users/ph_property_user/addResidents"
  public static UPDATE_PH_PROPERTY_USER = ApiService.BASE_NODE_URL + "ph_users/ph_property_user/updateResidentPid"
  public static GET_PH_PROPERTY_USER_DETAILS = ApiService.BASE_HOMES_NODE_URL + "ph_users/ph_property_user/details"
  public static GET_PH_PROPERTY_USER_TYPES_DETAILS = ApiService.BASE_NODE_URL + "ph_users/ph_property_user_types/details"
  public static DELETE_PH_PROPERTY_USER = ApiService.BASE_NODE_URL + "ph_users/ph_property_user"
  public static GET_MEMBER_BY_PID = ApiService.base_path + "Proppy/GetMemberByProppyUserID";
  public static GET_VISITORS_MY_DETAILS = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_request/myvisitdetails"
  public static UPDATE_VISITOR_REQUEST = ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_request"
  public static GENERATE_VISITOR_SHARE_IMG = ApiService.BASE_NODE_URL + "ph/misc/chb"
  public static ADD_ATTENDANCE = ApiService.BASE_NODE_URL + "ph_vms/attendance"
  public static Qr_LOGIN_SCAN= ApiService.AUTH_NODE_URL + 'auth/pro_qr_login_scan'

  //Community board
  // start
  public static ANNOUNCEMENTS_DETAILS_URL =
    ApiService.BASE_NODE_URL + "ph_announcement/ph_announcements/paging";
  public static ANNOUNCEMENTS_DETAILS =
    ApiService.BASE_NODE_URL + "ph_announcement/ph_announcements/details";
  public static GET_COMMUNITY_BOARD_POSTS =
    ApiService.BASE_NODE_URL +
    "ph_community_board/ph_community_details/details";
  public static COMMUNITY_BOARD_PAGING =
    ApiService.BASE_NODE_URL + "ph_community_board/ph_community_details/paging";
  public static ADD_POST_TO_COMMUNITY_BOARD =
    ApiService.BASE_NODE_URL + "ph_community_board/ph_community_details";
  public static GET_USER_POSTS_PAGING =
    ApiService.BASE_NODE_URL + "ph_community_board/ph_community_details/paging";
  public static GET_USER_POSTS =
    ApiService.BASE_NODE_URL +
    "ph_community_board/ph_community_details/details";
  public static DELETE_USER_POST =
    ApiService.BASE_NODE_URL + "ph_community_board/ph_community_details/";
  public static UPDATE_USER_POST =
    ApiService.BASE_NODE_URL + "ph_community_board/ph_community_details";
  public static UPLOAD_COMMUNITY_POST_IMAGE =
    cdn_portal + "ifile/api/File/UploadCommunityPostImage";
  // end

  // My Units Add Vehicles Start
  public static GET_VEHICLE_DATA_URL =
    ApiService.BASE_NODE_URL + "ph_visitor_management/ph_vehicle/details";
  public static ADD_VEHICLES_DATA_URL =
    ApiService.BASE_NODE_URL + "ph_visitor_management/ph_vehicle";
  public static UPDATE_VEHICLES_DATA_URL =
    ApiService.BASE_NODE_URL + "ph_visitor_management/ph_vehicle";
  // My Units Add Vehicles End

  // end
  // ***************** JMC Services START **************************//
  public static GET_JMC_PROFILE_INFORMATION =
    ApiService.BASE_NODE_URL + "ph_jmc/ph_profile/details";
  public static GET_COMMITTEE_MEMBER_INFORMATION =
    ApiService.BASE_NODE_URL + "ph_jmc/ph_committe_members/details";
  public static GET_MANAGEMENT_OFFICE_INFORMATION =
    ApiService.BASE_NODE_URL + "ph_management_office/mo_profile_trx/details";
  // ***************** JMC Services END **************************//
  // **************** Earn Services START ******************//
  public static RECORD_GEM_REWARD =
    ApiService.base_path + "proppygem/RecordGemReward";

  /******************* PANIC BUTTON SERVICES START ***********************/
  public static GET_PANIC_NOTIFICATIONS =
    ApiService.BASE_NODE_URL + "ph_panic/ph_panic_emergency/details";
  public static ADD_PANIC_NOTIFICATIONS =
    ApiService.BASE_NODE_URL + "ph_panic/ph_panic_emergency";
  /******************* PANIC BUTTON SERVICES END ***********************/

  /******************* BILLING NOTIFICATIONS START *****************************/
  public static GET_BILLING_NOTIFICATIONS =
    ApiService.BASE_NODE_URL + "billing/homes_hdr_trx/pending_bill_counts";
  /******************* BILLING NOTIFICATIONS END *****************************/

  //GAURD HOUSE
  public static VISIT_PURPOSE: any = [
    { name: "visitor", id: 0 },
    { name: "delivery", id: 1 },
    { name: "dropoff", id: 2 },
    { name: "pickup", id: 3 },
    { name: "invitation", id: 4 },
    { name: "overnight", id: 5 },
    { name: "contractor", id: 6 },
    { name: "worker", id: 7 },
  ];
  public static VEHICLE_TYP = [
    { vehicle_name: "car", id: 0 },
    { vehicle_name: "lorry", id: 1 },
    { vehicle_name: "van", id: 2 },
    { vehicle_name: "suv", id: 3 },
    { vehicle_name: "motor_cycle", id: 4 },
  ];

  public static GET_UNITS =
    ApiService.BASE_NODE_URL + "ph_property/units/details";
  public static ADD_WALKIN_VISITOR =
    ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_log";
  public static ADD_SELF_REGISTRATION =
    ApiService.BASE_NODE_URL + "ph_vms/ph_guard_house_qr_logger";
  public static UPDATE_VISITOR_APPROVAL =
    ApiService.BASE_NODE_URL + "ph_visitor_management/ph_property_visitor_log";
  public static GET_PROPERTIES =
    ApiService.BASE_NODE_URL + "ph_property/profile/details";
  public static PH_VEHICLES_MAKERS =
    ApiService.BASE_NODE_URL + "ph_vms/vehicle_masters/vehicle_makers";
  public static PH_VEHICLES_MODELS =
    ApiService.BASE_NODE_URL + "ph_vms/vehicle_masters/vehicle_models";
  //GAURD HOUSE END

  //MY PROFILE START
  public static GET_PROFILE_SETTINGS_DATA_BY_PID = ApiService.AUTH_NODE_URL + "my_profile/settings/details"
  public static UPDATE_PROFILE_SETTINGS_DATA_BY_PID = ApiService.AUTH_NODE_URL + "my_profile/settings"
  public static CHANGE_PASSWORD = ApiService.AUTH_NODE_URL + "auth/upwd_change";
  public static VIEW_UPDATE_BY_USERID = ApiService.BASE_NODE_URL + "my_account/account/update_profile";
  public static GET_CONTACT_DETAILS_BY_USERID = ApiService.BASE_NODE_URL + "contacts/contact_list/details";
  //MY PROFILE END

  // **************** Earn Services END ******************//
  public static FURNISHINGS = [
    {
      id: 1,
      name: "Unfurnished",
      lang_section_id: "124",
      lang_title_key: "U0GMODXQNU09W60WE15",
    },
    {
      id: 2,
      name: "Fully Furnished",
      lang_section_id: "124",
      lang_title_key: "YS6JOZUWSQNBBURWEF1",
    },
    {
      id: 3,
      name: "Partially Furnished",
      lang_section_id: "124",
      lang_title_key: "R5NYEHE9TXRCWRVT32E",
    },
  ];
  public static SORT_TYPES = [
    { id: 1, name: "Recent" },
    { id: 2, name: "Lowest Price" },
    { id: 3, name: "Highest Price" },
    { id: 4, name: "Built-up Size (large to small)" },
    { id: 5, name: "Built-up Size (small to large)" },
  ];
  public static TENURE_TYPES = [
    { id: 1, name: "Freehold" },
    { id: 2, name: "Leasehold" },
    { id: 3, name: "Malay Reserve Land" },
    { id: 4, name: "Private Lease Scheme" },
  ];
  public static ADVERTISER_TYPES = [
    { name: "Agent" },
    { name: "Developer" },
    { name: "Owner" },
    { name: "Broker" },
  ];
  public static LISTING_TYPES = [{ name: "Sale" }, { name: "Rent" }];
  public static PROPERTY_TYPES = [{ name: "Condominum" }, { name: "Terrace" }];
  public static UNIT_TYPES = [
    {
      name: "Intermediate",
      lang_section_id: "123",
      lang_title_key: "FFXHOXD55S1CZNS2A4F",
    },
    {
      name: "Corner Lot",
      lang_section_id: "123",
      lang_title_key: "1M5W6TSBQFUU4L9BJEX",
    },
    {
      name: "End Lot",
      lang_section_id: "123",
      lang_title_key: "LXG40Z3UJI99DVPEKDV",
    },
    {
      name: "Duplex",
      lang_section_id: "123",
      lang_title_key: "PWGN1SRTA4LPY0OBL05",
    },
    {
      name: "Triplex",
      lang_section_id: "123",
      lang_title_key: "5XZX2DEEHLYK49DSWXV",
    },
    {
      name: "Penthouse",
      lang_section_id: "123",
      lang_title_key: "4CN68FMFXVN4WRYVK59",
    },
    {
      name: "Studio",
      lang_section_id: "123",
      lang_title_key: "PK0ZLM6EI57SQ3QZ947",
    },
    {
      name: "Soho",
      lang_section_id: "123",
      lang_title_key: "GI48JHNW9J24C70OVET",
    },
    {
      name: "Dual key",
      lang_section_id: "123",
      lang_title_key: "ISLYZPUS4JZ2AFOJMQZ",
    },
  ];
  public static FLOOR_LEVEL = [
    { name: "High Floor" },
    { name: "Middle Floor" },
    { name: "Low Floor" },
  ];
  public static BUILT_UP_UOM = [
    {
      name: "Square Feet",
      value: "sq.ft",
      lang_section_id: "2",
      lang_title_key: "identity_22",
    },
    // {'name':'Sq.Meter','value':'sq.m'},
    {
      name: "Acres",
      value: "acres",
      lang_section_id: "2",
      lang_title_key: "identity_23",
    },
  ];
  public static LAND_TITLE = [
    { name: "Residential" },
    { name: "Commercial" },
    { name: "Agriculture" },
  ];
  public static PROPERTY_TITLE_TYPE = [
    {
      name: "Master",
      lang_section_id: "126",
      lang_title_key: "W5YGLDU8JTU37DKL2ZF",
    },
    {
      name: "Individual",
      lang_section_id: "126",
      lang_title_key: "0IW5S1S85KLC73U8JQK",
    },
    {
      name: "Strata",
      lang_section_id: "126",
      lang_title_key: "ED4ES9TQIZSEFZXD7U9",
    },
  ];
  public static TENURE = [
    { name: "Freehold" },
    { name: "Leasehold" },
    { name: "Malay Reserve Land" },
    { name: "Lease & Scheme" },
  ];
  public static FURNISHING = [
    { name: "Partially Furnished" },
    { name: "Fully Furnished" },
    { name: "Unfurnished" },
  ];
  public static OCUUPANCY = [
    {
      name: "Tenanted",
      lang_section_id: "125",
      lang_title_key: "OOVOOCJ05Z7L597977R",
    },
    {
      name: "Owner Occupied",
      lang_section_id: "125",
      lang_title_key: "SAS8URCCE2XV2E8G8A5",
    },
    {
      name: "Vacant",
      lang_section_id: "125",
      lang_title_key: "QM7MD8FV8FEDS1GPNZM",
    },
  ];
  public static NUMBERS = [
    { Name: 1 },
    { Name: 2 },
    { Name: 3 },
    { Name: 4 },
    { Name: 5 },
    { Name: 6 },
    { Name: 7 },
    { Name: 8 },
    { Name: 9 },
    { Name: 10 },
  ];

  public static DISTANCE = [
    { name: "10 Meters", value: 10 },
    { name: "20 Meters", value: 20 },
    { name: "30 Meters", value: 30 },
    { name: "40 Meters", value: 40 },
    { name: "50 Meters", value: 50 },
    { name: "100 Meters", value: 100 },
    { name: "150 Meters", value: 150 },
    { name: "200 Meters", value: 200 },
    { name: "250 Meters", value: 250 },
    { name: "300 Meters", value: 300 },
  ];

  public static AGENTIMAGE =
    "https://res.cloudinary.com/realtysystems/image/upload/v1613532064/agents/";
  public static MERCHANT_GROUP_BTN_URL_PREFIX =
    cdn_portal + "images/AppImages/ProppyCaresMap/Legend/";
  public static MERCHANT_SERVICE_PROVIDER_URL_PREFIX =
    cdn_portal + "images/ui24/ProppyCaresCategory/";

  // public static GET_PROPERTIES_BY_FILTERV2=ApiService.base_path+'PropertyMarket/GetPropertiesByFilterV2';
  // public static GET_PROPERTIES_BY_FILTERMAPV2=ApiService.base_path+'PropertyMarket/GetPropertiesByFilterMapV2';

  public static ERROR_TOAST_MSG = 'Unable to process this request, please try again.'
  public static MANDATORY_TOAST_MSG = 'Please Fill All Mandatory Fields To Continue.'
  public static DISPLAY_COL_DROPDOWN_ROUTE = 'tabs/display-col-dropdown'
  public static DISPLAY_DROPDOWN_ROUTE = "tabs/display-dropdown"
  public static EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/
  public static APP_SUPPORTED_LANGUAGES = [{ Name: 'English', Code: 'en' }, { Name: 'Chinese', Code: 'cn' }, { Name: 'Malay', Code: 'ms' }]
  public static APP_SUPPORTED_LANGUAGES_BY_CODE = { 'en': 'English', 'cn': 'Chinese', 'ms': 'Malay' }
  public static MERCHANT_TRANSFER_NOT_ALLOWED = 'TRANSFER IS NOT ALLOWED';

  /** ENVIRONMENT CONFIGURATION */
  public static ADMOB_APP_ID = environment.admob_app_id;
  public static IOS_ADMOB_APP_ID = environment.ios_admob_app_id;
  public static ANDROID_GOOGLE_MAP_KEY = environment.android_google_map_key;
  public static IOS_GOOGLE_MAP_KEY = environment.ios_google_map_key;
  public static TALK_JS_APP_ID = environment.talkjs.appId;
  //** END */

  // Deep Link Api
  public static EVENT_PREFIX = ApiService.base_path + "ProEvents/";
  public static CREATE_DEEPLINK_EVENT = ApiService.EVENT_PREFIX + "AddData";
  public static RECORD_DEEPLINK_EVENT_ACCESS =
    ApiService.EVENT_PREFIX + "RecordEventAccess";
  public static GET_DEEPLINK_EVENT_BY_ACCESSID =
    ApiService.EVENT_PREFIX + "GetDataByAccessID";
  // End
  //MAO
  public static GET_QUESTIONS =
    ApiService.base_path + "QuestionTemplate/GetData";
  public static SAVE_LISTING_SELECTED_QUESTIONS =
    ApiService.base_path + "ProppyPropertyMarketMAOQueTemp/AddData";
  public static GET_LISTING_SELECTED_QUESTIONS =
    ApiService.base_path + "ProppyPropertyMarketMAOQueTemp/GetData";
  public static SAVE_LISTING_MAO =
    ApiService.base_path + "ProppyPropertyMarketMAO/AddData";
  //End

  //AI Service Url's
  public static ADD_EMAIL_QUEUE = ApiService.base_path + "Email/AddEmailQueue";
  //End

  //appeal board
  public static GET_REPORTED_LISTING_BY_OBJECT_ID = ApiService.base_path + 'Report/GetReportedListingByObjectID';
  public static GET_REPORTED_REASON_LISTING_BY_OBJECT_ID = ApiService.BASE_NODE_URL + "report/report_reason/reported_listing_by_objectId";

  public static APPEAL_REPORT = ApiService.base_path + 'Report/ApealReport'
  public static APPEAL_REPORT_REASON = ApiService.BASE_NODE_URL + "report/report_reason/apeal_report";

  //end

  //premium merchant
  public static GET_MERCHANT_DETAILS = ApiService.BASE_NODE_URL + "premium_merchant/promoter_merchants/details";
  public static GET_MERCHANT_DEATILS = ApiService.BASE_NODE_URL + "premium_merchant/merchant/details";
  public static ADD_OR_UPDATE_MERCHANT_DETAILS = ApiService.BASE_NODE_URL + "premium_merchant/merchant";
  public static GET_MERCHANT_PAGING = ApiService.BASE_NODE_URL + "premium_merchant/merchant/paging";
  public static GET_PREMIUM_MERCHANT_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/pmd/details';
  public static GET_PREMIUM_MERCHANT_PAGING = ApiService.BASE_NODE_URL + 'premium_merchant/pmd/paging';
  public static GET_PREMIUM_MERCHANT_MAP_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/pmd/map_details';
  public static GET_PREMIUM_MERCHANT_PRODUCT_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/product/details'
  public static GET_HIGHLY_ENDORSED_MERCHANTS = ApiService.BASE_NODE_URL + 'premium_merchant/most_endorsed_merchants/most_endorsed_merchants';
  public static GET_MAP_MERCHANT_EVENTS_VOUCHERS=ApiService.MARKET_360_NODE_URL +"premium_merchant/pmd/merchant_events_vouchers";

  public static GET_PREMIUM_MERCHANT_MAP_DETAILS_V2 = ApiService.MARKET_360_NODE_URL + 'premium_merchant/pmd/map_details';

  public static GET_CART_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/cart/details'
  public static ADD_CART_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/cart'
  public static CLEAR_CART_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/cart/clear'
  public static GET_PAYMENT_BANK_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/payment_banks/details';
  public static ADD_ORDER_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/order_hdr'
  public static GET_VOUCHER_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/discount_voucher/details'
  public static DISCOUNT_VOUCHER_COLLECTED = ApiService.BASE_NODE_URL + 'premium_merchant/discount_voucher_collected'
  public static DISCOUNT_VOUCHER_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/discount_voucher_collected/details';
  public static DISCOUNT_VOUCHER_PAGING = ApiService.BASE_NODE_URL + 'premium_merchant/discount_voucher/paging';
  public static ADD_ORDERS = ApiService.BASE_NODE_URL + 'premium_merchant/order_hdr';
  public static GET_SYSTEM_PARAMETERS = ApiService.BASE_NODE_URL + 'premium_merchant/merchant_sys_parameter/details';
  public static GET_PROMOTION_DETAILS = ApiService.BASE_NODE_URL + 'premium_merchant/promotion/details';

  public static GET_PROMOTION_HIERARCHY_DETAILS_V2: string = ApiService.BASE_NODE_URL + 'premium_merchant/promotion_v2/details';

  public static ADD_PROMOTION_REFERRAlS = ApiService.BASE_NODE_URL + 'premium_merchant/promotion_referrals';
  public static GET_PROMOTION_REFERRALS = ApiService.BASE_NODE_URL + 'premium_merchant/promotion_referrals/details';
  public static GENERATE_PM_ORDER_RECEIPT = ApiService.BASE_NODE_URL + "pdf/pmr"
  public static MERCHANT_BANK_DETAILS = ApiService.BASE_NODE_URL + "premium_merchant/payment_method/details"
  public static REDEMPTION_PRODUCT_DETAILS = ApiService.BASE_NODE_URL + "premium_merchant/pc_redemption_products/details";
  public static GET_BANKS = ApiService.BASE_NODE_URL + "premium_merchant/bank_master/details";
  public static GET_PRODUCT_CATEGORY_DETAILS = ApiService.BASE_NODE_URL + "premium_merchant/pc_custom_product_categories_hierarchy/details";
  public static UPLOAD_ORDER_PAYMENT_SLIP = cdn_portal + "ifile/api/File/UploadPMOrderPaymentSlip"
  //end
  //*********************Product as a service*************************************
  public static GET_SERVICE_PRODUCT_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/pc_service_products/details/";

  //end
  //*********************USER SIGN UP*************************************
  //START
  public static GET_PROPPY_USER_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/user_info/details";
  public static ADD_MERCHANT_USER_SIGNUP =
    ApiService.BASE_NODE_URL + "premium_merchant/merchant_user_signup";
  public static GET_MERCHANT_REQ_USER_SIGNUP_DETAILS =
    ApiService.BASE_NODE_URL +
    "premium_merchant/merchant_signup_parameter_config/details";
  public static DELETE_USER_SIGNUP =
    ApiService.BASE_NODE_URL + "premium_merchant/merchant_user_signup/clear";
  public static GET_USER_SIGN_YN =
    ApiService.BASE_NODE_URL + "premium_merchant/merchant_user_signup/details";
  public static GET_USER_MEMBERSHIP_DETAILS =
    ApiService.BASE_NODE_URL +
    "premium_merchant/customer/user_membership_details";
  //end
  //*********************influence-transaction******************************
  public static GET_PROMO_TRANSACTIONS =
    ApiService.BASE_NODE_URL + "premium_merchant/order_hdr_trx/details";
  public static GET_PROMOTION_HIRARCHY_DETAILS =
    ApiService.BASE_NODE_URL + "premium_merchant/order_hdr/phd";
  //end

  // *********** PRELOVED SERVICES START ***************//
  // public static GET_PRELOVED_CATEGORIES =ApiService.BASE_NODE_URL + 'pre_loved/pre_loved_category/details';
  // public static GET_PRELOVED_SUB_CATEGORIES =ApiService.BASE_NODE_URL + 'pre_loved/pre_loved_sub_category/details';
  public static GET_PRELOVED_CATEGORIES =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_category/details";
  // public static GET_PRELOVED_SUB_CATEGORIES =ApiService.BASE_NODE_URL +'pre_loved/pre_loved_sub_category/details';
  public static ADD_PRELOVED =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_listing";
  public static PRELOVED_BUY_REQUEST =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_buy_requests";
  public static PRELOVED_MAO_REQUEST =
    ApiService.BASE_NODE_URL + "mao/proppy_property_market_mao";

  public static PRELOVED_PM_PROGRESS_TRACKER =
    ApiService.BASE_NODE_URL + "pre_loved/pm_progress_tracker";
  public static ADD_PRELOVED_DEALS =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_deals";

  public static UPLOAD_PRELOVED_IMAGES =
    cdn_portal + "ifile/api/File/UploadPrelovedImage";
  public static PRELOVED_DELETE_IMAGE =
    ApiService.BASE_NODE_URL + "pre_loved/pre_loved_listing_img/";
  // *********** PRELOVED SERVICES END ***************//

  //*********************influence-transaction******************************
  public static PROPPY_NOTIFICATION_DETAILS =
    ApiService.BASE_NODE_URL +
    "proppy_notification/proppy_notifications/details";
  public static PROPPY_NOTIFICATION =
    ApiService.BASE_NODE_URL + "proppy_notification/proppy_notifications";
  public static PROPPY_NOTIFICATION_PAGING =
    ApiService.BASE_NODE_URL +
    "proppy_notification/proppy_notifications/paging";
  //end

  //*********************Access Upload ******************************/
  public static ACCESS_IMAGE_UPLOAD =
    cdn_portal + "ifile/api/File/UploadAccessImage";
  //end

  /****************************** BILLING API START ********************************/
  public static GET_BILL_GENERATED_INVOICES = ApiService.BASE_NODE_URL + 'billing/homes_hdr_trx/details'
  public static GET_ADHOC_INVOICE_BILLS = ApiService.BILLING_BASE_NODE_URL + 'generation/adhoc/details';
  public static UPDATE_BILL_PAY_INVOICES = ApiService.BASE_NODE_URL + 'billing/homes_hdr_trx'
  public static BILL_PAYMENT_INVOICE_URL = ApiService.BASE_NODE_URL + 'billing/homes_hdr_trx/payments'
  public static BILL_ADHOC_PAYMENT_INVOICE_URL = ApiService.BILLING_BASE_NODE_URL + 'payments/adhoc'
  public static ADVANCED_DEPOSIT_PAYMENT_INVOICE_URL = ApiService.BILLING_BASE_NODE_URL + 'payment_receipt/adhoc/adv_deposit_payment'
  public static UPLOAD_BILLING_RECEIPTS = cdn_portal + 'ifile/api/File/UploadPropertyBillingReceipt';

  public static GENERATE_INVOICE_PDF = ApiService.BASE_NODE_URL + 'billing/homes_hdr_trx/generate_pdf';
  public static GENERATE_INVOICE_ADHOC_PDF = ApiService.BILLING_BASE_NODE_URL + "generation/adhoc/gen_pdf"

  public static GENERATE_PAYMENT_RECEIPT_PDF = ApiService.BASE_NODE_URL + 'billing/payment_receipt/generate_pdf';
  public static GENERATE_PAYMENT_RECEIPT_ADHOC_PDF = ApiService.BILLING_BASE_NODE_URL + "payment_receipt/adhoc/gen_pdf"

  public static GET_S3_SIGNED_URL = ApiService.BASE_NODE_URL + "aws/get_signed_url"

  public static BULLING_ADVANCE_DEPOSIT_PDF = ApiService.BILLING_BASE_NODE_URL + "payment_receipt/adhoc/adv_deposit_payment/gen_pdf"

  /****************************** BILLING API END ********************************/



  /*************************** FORMS SERVICES START **********************************/
  public static ADD_FORM_APPLICATION_MASTER_DETAILS =
    ApiService.BASE_NODE_URL + "ph/forms/fm/";
  public static GET_FORM_APPLICATION_MASTER_DETAILS =
    ApiService.BASE_NODE_URL + "ph/forms/fm/details";
  public static GET_FORM_APPLICATION_MASTER_PAGING =
    ApiService.BASE_NODE_URL + "ph/forms/fm/paging";
  public static ADD_FROM_APPLICATION_DETAILS =
    ApiService.BASE_NODE_URL + "ph/forms/fms/";
  public static GET_FROM_APPLICATION_DETAILS =
    ApiService.BASE_NODE_URL + "ph/forms/fms/details";
  public static GET_FORM_APPLICATION_PAGING =
    ApiService.BASE_NODE_URL + "ph/forms/fm/paging";
  public static FORMS_MOVING_DATA = [
    { id: 1, name: "Moving In" },
    { id: 2, name: "Moving Out" },
  ];
  public static FORMS_TYPES_OF_SERVICES = [
    { id: 1, name: "Air Cond Service" },
    { id: 2, name: "New Air Cond Installation" },
    { id: 3, name: "Air Cond Breakdown" },
    { id: 4, name: "Cleaning" },
    { id: 5, name: "Repairing" },
    { id: 6, name: "Installation Wifi" },
    { id: 7, name: "Moving Out" },
  ];

  /*************************** FORMS SERVICES START **********************************/

  /*************************** E-Badges SERVICES START **********************************/
  public static GET_MERCHANT_BADGES = ApiService.MARKET_360_NODE_URL+"e_badge/master_badges/details"
  public static GET_MERCHANT_ASSIGNED_BADGES = ApiService.MARKET_360_NODE_URL+"e_badge/user_badges/details"
  public static GET_USER_BADGES = ApiService.MARKET_360_NODE_URL+"e_badge/user_badges/details"
  /*************************** E-Badges SERVICES START **********************************/

  /************************** REGULAR EXPRESSIONS START ***********************************/
  public static REGEX_FOR_LETTERS_AND_SPACE = /^[a-zA-Z\s]*$/;
  public static REGEX_FOR_LETTERS_AND_NUMBERS = /^[a-zA-Z0-9_.-]*$/;
  public static REGEX_FOR_ONLY_NUMBERS = /^[0-9]*$/;
  public static REGEX_FOR_DECIMAL_NUMBERS = /^\d*\.?\d*$/;
  public static REGEX_FOR_ONLY_NUMBERS_LETTERS = /^[A-Za-z0-9]*$/;
  public static REGEX_FOR_ALPHANUMERIC_AND_SPACE =
    /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
  public static REGEXP_ALLOW_PLUS_AND_NUMBERS = /^[0-9 +]*$/;
  public static REGEXP_ALLOW_ALL = /^[a-zA-Z0-9!/@#\$%\^\&*\)\(\'`'+=,._-\s]+$/;
  public static REGEXP_EMAIL = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
  public static REGEXP_VALID_URL = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  public static REGEXP_PASSWORD = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{3,25}$/

  /************************** REGULAR EXPRESSIONS END ***********************************/

  /****************************** PACKAGE ROOM START ********************************/
  public static GET_PACKAGES = ApiService.BASE_NODE_URL + "ph/pr/details";
  public static UPDATE_PACKAGE = ApiService.BASE_NODE_URL + "ph/pr";
  /****************************** PACKAGE ROOM END ********************************/

  /**************************** MARKET PLACE SAERVICES START************************************/
  public static GET_PROPPY_ACADEMY_CATEGORIES = ApiService.BASE_NODE_URL + "proppy_academy/academy_categories/details";
  public static GET_PROPPY_ACADEMY_CONTENT = ApiService.BASE_NODE_URL + "proppy_academy/academy_content/details";

  /**************************** MARKET PLACE SAERVICES END************************************/

  /**************************** BUMP SERVICES END************************************/
  public static ACTIVATE_BUMP = ApiService.MARKET_360_NODE_URL + "bump/bump_log";
  public static GET_USER_BUMP_DETAILS = ApiService.MARKET_360_NODE_URL + "bump/bump_log/details";
  /**************************** BUMP SERVICES END************************************/

  static LBDB_LANG_CODE = "lang_code";
  static DEFAULT_LANG_CODE = "en";
  static SELECTED_LANGUAGE_CODE = "en";
  static DB_LNG_KEY = "PROPPY_SELECTED_LANGUAGE";
  public getBalance(payload) {
    return this.http.post(
      ApiService.base_path + "proppygem/get-balance",
      payload
    );
  }

  public sendGems(payload) {
    return this.http.post(
      ApiService.base_path + "proppygem/gem-transfer",
      payload
    );
  }

  public static CHEST_DETAILS =
    ApiService.BASE_NODE_URL + "proppy_gems/chest/details";

  public getGemTransferHistory(payload) {
    return this.http.post(
      ApiService.base_path + "proppygem/get-transfer-history",
      payload
    );
  }

  public getGemTransferDetail(payload) {
    return this.http.post(
      ApiService.base_path + "proppygem/get-transfer-detail",
      payload
    );
  }

  public createRedPacket(payload) {
    return this.http.post(
      ApiService.base_path + "proppygem/red-packet-create",
      payload
    );
  }

  public getRedPacket(payload) {
    return this.http.post(
      ApiService.base_path + "proppygem/red-packet-detail",
      payload
    );
  }

  public getRedPacketSent(userID) {
    return this.http.post(
      ApiService.base_path + "proppygem/get-red-packet-sent",
      userID
    );
  }

  public getRedPacketReceived(userID) {
    return this.http.post(
      ApiService.base_path + "proppygem/get-red-packet-received",
      userID
    );
  }

  public claimRedPacket(payload) {
    return this.http.post(
      ApiService.base_path + "proppygem/red-packet-claim",
      payload
    );
  }

  public static RED_PACKET_QR_SHARE =
    ApiService.BASE_NODE_URL + "proppy_gems/red_packet/redpacketqr";

  public static getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)[
      "__zone_symbol__originalInstance"
    ];
    return zoneOriginalInstance || fileReader;
  }
  static BASE_ASSETS_PATH = "assets/";
  static BASE_JSON_PATH = ApiService.BASE_ASSETS_PATH + "json/";
  public static BASE_LT_IMAGES_PATH =
    ApiService.BASE_ASSETS_PATH + "lt_json_img";

  public static TALK_JS_BASE_URL = `https://api.talkjs.com/v1/${ApiService.TALK_JS_APP_ID}/`;
  public static GET_TALK_JS_CONVERSATION_URL =
    ApiService.TALK_JS_BASE_URL + `conversations/`;
  public static GET_TALK_JS_CONVERSATION_BY_PID_URL =
    ApiService.TALK_JS_BASE_URL + "users/${userId}/conversations  ";
  public static SAVE_TALK_JS_ATTACHMENT_URL =
    ApiService.TALK_JS_BASE_URL + `files`;
  public static ESTABLISH_TALK_JS_CONVERSATION_URL =
    ApiService.TALK_JS_BASE_URL + `conversations/`;

  static MONTH_NAMES = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];
  static SINGLELETTERWEEKDAYS = ["S", "M", "T", "W", "T", "F", "S"];
  static THREELETTERWEEKDAYS = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ];

  constructor(private http: HttpClient, private helper: AppHelper) { }

  public static HOMES_WELCOME_POPUP_GLOBAL = "HOMES_WELCOME_POPUP_GLOBAL";
  public static BEEP_NOTIFICATIONS_COUNT =
    ApiService.BASE_NODE_URL + "proppy_notification/notifications_count";
  public static CART_COUNT =
    ApiService.BASE_NODE_URL + "premium_merchant/cart/cart_count";
  public static HOMETAB_COUNT =
    ApiService.BASE_NODE_URL + "proppyhomes_tabs/tabs_count";
  public static MOP_COUNT = ApiService.BASE_NODE_URL + "mop/mop_counts";

  static deeplinkCallBack = null;
  static clearGlobalSearchData = null;

  public static IS_SLOW_INTERNET = false;
  static ENABLE_FD = true;
  static FD_BASE_URL = environment.fd_api_url + "api/v2/";
  static FD_SEARCH_CONTACT_URL = `${ApiService.FD_BASE_URL}search/contacts?query="email:'$$EMAIL$$'"`;
  static FD_CREATE_CONTACT_URL = `${ApiService.FD_BASE_URL}contacts`;
  // ProppyHomes Feature Codes ------
  public static AnnouncementCode = "MF230331172408170IF";
  public static CommunityBoardCode = "MF230331172408173KU";
  public static FacilityManagementCode = "MF230331172408173FV";
  public static VisitorManagementCode = "MF230331172408173ZU";
  public static IncidentManagementCode = "MF230331172408173QG";
  public static FormsCode = "MF230331172408173MV";
  public static PackageRoomCode = "MF230331172408173OV";
  public static BillingCode = "MF230331172408173PR";
  public static PanicEmergencyCode = "MF230331172408173TV";

  //Premium Merchant Default Share Image
  public static DEFAULT_IMAGE_PRODUCT_SERVICE_SHARE =
    "https://cdn.proppyapp.net/images/AppImages/PremiumMerchant/default_product_image.png";

  /*************************************START SQL Error Codes *****************************/
  public static SQL_ERROR_CODE_MESSAGES = {
    0: "Invalid Response From Server Please Try Again Later.",
    2601: "Record Already Exist.",
  };
  /*************************************END SQL Error Codes *****************************/
  // Beep Notification Count
  public static BeepNotificationCount = 0

  /** S3 API URL'S  */
  public static GET_S3_FILE_UPLOAD_URL =
    ApiService.BASE_NODE_URL + "aws/get_upload_url";
  public static GET_S3_UPLOADED_FILE_URL =
    ApiService.BASE_NODE_URL + "aws/get_signed_url";

  public static REQUEST_LOGIN_OTP = ApiService.BASE_NODE_URL + 'auth/send_otp'
  public static VERIFY_LOGIN_OTP = ApiService.BASE_NODE_URL + 'auth/verify_otp'

  public static BUCKET_TYPE = 3;
  public static MIME_TYPE = {
    pdf: "application/pdf",
    all_images: "image/[*]",
    image_png: "image/png",
    image_jpeg: "image/jpeg;",
  };
  public static FOLDER_PDF = "PDF";

  public static Product_check_status: number = 1;
  static LOGOUT_REDIRCT_LINK = '/login';
  static LBDB_KEY = 'pr_app_data';
  static LBDB_IS_LOGIN = 'pro_is_login';
  static LBDB_TOKEN_KEY = 'ph_token'; //Token
  static LBDB_PUSH_KEY = "LBDB_PUSH_KEY"
  static PUSH_NOTIFICATION_TOKEN = ""
  static PROPPYAPP_APP_ID = 1

  public static PROPPY_APP_ID = 1;
  public static ENCRYPT_KEY = 'mOIWb5YQRoJL7PgZ@$'

  public static INSTANT_TOKEN = ApiService.BASE_NODE_URL + "auth/instant_token";
  public static LOGIN = ApiService.BASE_NODE_URL + "auth/login";
  public static LOGIN_AC = ApiService.BASE_NODE_URL + "auth/login_ac";
  public static UPDATE_LOGIN_ACCESSTOKEN = ApiService.BASE_NODE_URL + 'auth/update_login_access';

  public static GATEWAY_PAYMENT_METHODS = ['RAZER', 'PAYEX']
  public static IS_SKIP_LOGIN = 0
  public static USER_VOUCHER_COUNT: string = ApiService.SHARED_NODE_URL + `counts/vouchers_count/details`;
  public static USER_DEALS_COUNT: string = ApiService.SHARED_NODE_URL + `counts/deals_count/details`;
  public static IS_APP_POPUP_SHOW:number = 1;
  public static DISMISS_APP_POPUP_CB:any = () => {};

  //************* PROMOTION SERVICE URLS START  ***************/

  public static ADD_OR_UPDATE_PROMOTER_DETAILS: string = `${ApiService.MARKET_360_NODE_URL}promoter/pc_promoter`;
  public static GET_PROMOTER_DETAILS: string = `${ApiService.MARKET_360_NODE_URL}promoter/pc_promoter/details`;

  public static GET_PROMOTER_MERCHNATS_LIST: string = `${ApiService.MARKET_360_NODE_URL}promoter/pc_promoter/merchants_list`;
  public static GET_USER_MERCHANT_PROMO_CODE_DETAILS:string = `${ApiService.MARKET_360_NODE_URL}promoter/pc_promoter/merchant_promocodes`

  // WPE SERVICE LIST 

  public static GET_WALL_POST_PROPPER_DETAILS_PAGING: string = ApiService.WPE_NODE_URL + `wall/propper_wall_post/paging`;
  public static GET_WALL_EVENT_PROPPER_NEAR_DETAILS:string = ApiService.WPE_NODE_URL + `wall/propper_wall_event/paging`
  // Dynamic Qr
  public static GET_QR_LINK_DETAILS : string = ApiService.BASE_P360_NODE_API_URL + 'dynamic_qr/qr_link/details'
  public static GET_PROMO_CODE_DETAILS: string = `${ApiService.MARKET_360_NODE_URL}promoter/pc_promocode/details`;
  public static GET_AVAIL_PROMO_PRODUCTS_AND_SERVICES: string = `${ApiService.MARKET_360_NODE_URL}promoter/pc_promocode/avail_products_services`;

//************* PROMOTION PAYOUT SERVICES***************/
  public static GET_PROMOTER_COMM_PAYOUT_DETAILS: string = `${ApiService.MARKET_360_NODE_URL}promoter/pc_comm_payout/details`;
  public static UPDATE_PROMOTER_COMM_PAYOUT_DETAILS: string = `${ApiService.MARKET_360_NODE_URL}promoter/pc_comm_payout`;
  public static GET_PROMO_CODE_HIERARCHY_PROMOTER_PROMOCODES:string = `${ApiService.MARKET_360_NODE_URL}campaign/campaign_promoter`;

  public static CHECKOUT_CALCULATION_DETAILS:string = `${ApiService.MARKET_360_NODE_URL}checkout/cal`
  public static PC_FEATURED_MERCHANTS_DETAILS:string = `${ApiService.MARKET_360_NODE_URL}premium_merchant/featured_merchants/details`
  public static GET_MERCHANT_MAP_SPECIFIC_DETAILS:any = `${ApiService.MARKET_360_NODE_URL}premium_merchant/pmd/merchant_events_endorsement_rating`
  public static GET_USERS_CONTACT_LIST = ApiService.BASE_NODE_URL + `proppy_contact_list/contact_list/details`;
  public static GET_COMMISION_TRX__INFO_DETAILS:string = `${ApiService.MARKET_360_NODE_URL}commission_trx/comm_info`;

  public static GET_MEMBERSHIP_TRANSACTION_HISTORY = `${ApiService.MARKET_360_NODE_URL}premium_merchant/membership_transaction_history/details`

  public static IS_APPLE_SIGNIN_ENABLED=1 //0 means false , 1 means true;

}

