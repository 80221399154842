import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Platform } from '@ionic/angular';
@Pipe({
  name: 'imgSanitizerPipe',
  pure: false,
})
export class ImageSanitizer implements PipeTransform {
  constructor(private plt: Platform, private sanitizer: DomSanitizer,private webview:WebView) {}
  transform(img_url: any): unknown {
    console.log(img_url,'img_url');
    if ((img_url || '').startsWith('http')) {
      return img_url;
    }
    if (this.plt.is('ios')) {
      let converted = this.webview.convertFileSrc(img_url);
      const safeImg = this.sanitizer.bypassSecurityTrustUrl(converted);
      return safeImg;
    }
    return img_url;
  }
}
