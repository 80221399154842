import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { PropertyAgentPageModule } from "./property-agent/property-agent.module";
import { AuthGuardService as AuthGuard } from "./services/middleware/auth-guard.service";
import { PreloadModulesService } from "./services/preloadmodule.service";
const routes: Routes = [
  {
    path: "",
    redirectTo: "splash",
    pathMatch: "full",
  },
  {
    path: "tabs",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "c360",
    loadChildren: () =>
      import("./community360/community360.module").then(
        (m) => m.Community360Module
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignupPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "subcategory",
    loadChildren: () =>
      import("./subcategory/subcategory.module").then(
        (m) => m.SubcategoryPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "mortgageform",
    loadChildren: () =>
      import("./mortgageform/mortgageform.module").then(
        (m) => m.MortgageformPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "mortgagebenefits",
    loadChildren: () =>
      import("./mortgagebenefits/mortgagebenefits.module").then(
        (m) => m.MortgagebenefitsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "specialservices",
    loadChildren: () =>
      import("./specialservices/specialservices.module").then(
        (m) => m.SpecialservicesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "propertymarket",
    loadChildren: () =>
      import("./propertymarket/propertymarket.module").then(
        (m) => m.PropertymarketPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "fleamarket",
    loadChildren: () =>
      import("./fleamarket/fleamarket.module").then(
        (m) => m.FleamarketPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "categorysub",
    loadChildren: () =>
      import("./categorysub/categorysub.module").then(
        (m) => m.CategorysubPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "merchants",
    loadChildren: () =>
      import('./proppy-cares/proppy-cares.module').then(m => m.ProppyCaresPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "merchantdetails",
    loadChildren: () =>
      import("./premium-merchant/merchant-details-v3/merchant-details-v3.module").then(
        (m) => m.MerchantDetailsV3PageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "userprofile",
    loadChildren: () =>
      import("./userprofile/userprofile.module").then(
        (m) => m.UserprofilePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "modal-shop-cart",
    loadChildren: () =>
      import("./modal-shop-cart/modal-shop-cart.module").then(
        (m) => m.ModalShopCartPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "modal-mortgage-diag",
    loadChildren: () =>
      import("./modal-mortgage-diag/modal-mortgage-diag.module").then(
        (m) => m.ModalMortgageDiagPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "modal-mortgage-diag-report",
    loadChildren: () =>
      import(
        "./modal-mortgage-diag-report/modal-mortgage-diag-report.module"
      ).then((m) => m.ModalMortgageDiagReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "mortgage-reports",
    loadChildren: () =>
      import("./mortgage-reports/mortgage-reports.module").then(
        (m) => m.MortgageReportsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "mortgage-must-know",
    loadChildren: () =>
      import("./mortgage-must-know/mortgage-must-know.module").then(
        (m) => m.MortgageMustKnowPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "payment-stripe",
    loadChildren: () =>
      import("./payment-stripe/payment-stripe.module").then(
        (m) => m.PaymentStripePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "payment-success",
    loadChildren: () =>
      import("./payment-success/payment-success.module").then(
        (m) => m.PaymentSuccessPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "fleamarket-details",
    loadChildren: () =>
      import("./fleamarket-details/fleamarket-details.module").then(
        (m) => m.FleamarketDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "propertymarket-details",
    loadChildren: () =>
      import("./propertymarket-details/propertymarket-details.module").then(
        (m) => m.PropertymarketDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "mortgagepayoptions",
    loadChildren: () =>
      import("./mortgagepayoptions/mortgagepayoptions.module").then(
        (m) => m.MortgagepayoptionsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "diagnose-wizard",
    loadChildren: () =>
      import("./diagnose-wizard/diagnose-wizard.module").then(
        (m) => m.DiagnoseWizardPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "update-personal-details",
    loadChildren: () =>
      import("./update-personal-details/update-personal-details.module").then(
        (m) => m.UpdatePersonalDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "mortgagereportsschedule",
    loadChildren: () =>
      import("./mortgagereportsschedule/mortgagereportsschedule.module").then(
        (m) => m.MortgagereportsschedulePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "tab4",
    loadChildren: () =>
      import("./tab4/tab4.module").then((m) => m.Tab4PageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "tab5",
    loadChildren: () =>
      import("./tab5/tab5.module").then((m) => m.Tab5PageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "butler",
    loadChildren: () =>
      import("./butler/butler.module").then((m) => m.ButlerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "embassy",
    loadChildren: () =>
      import("./embassy/embassy.module").then((m) => m.EmbassyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "service-provider",
    loadChildren: () =>
      import("./service-provider/service-provider.module").then(
        (m) => m.ServiceProviderPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "reno-repairs",
    loadChildren: () =>
      import("./reno-repairs/reno-repairs.module").then(
        (m) => m.RenoRepairsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "advertiser",
    loadChildren: () =>
      import("./advertiser/advertiser.module").then(
        (m) => m.AdvertiserPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "mortgageoverview",
    loadChildren: () =>
      import("./mortgageoverview/mortgageoverview.module").then(
        (m) => m.MortgageoverviewPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "discovery",
    loadChildren: () =>
      import("./discovery/discovery.module").then((m) => m.DiscoveryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "invitation",
    loadChildren: () =>
      import("./invitation/invitation.module").then(
        (m) => m.InvitationPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "butler-notification",
    loadChildren: () =>
      import("./butler-notification/butler-notification.module").then(
        (m) => m.ButlerNotificationPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-business",
    loadChildren: () =>
      import("./proppy-business/proppy-business.module").then(
        (m) => m.ProppyBusinessPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "marketplacenew",
    loadChildren: () =>
      import("./marketplacenew/marketplacenew.module").then(
        (m) => m.MarketplacenewPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "chat",
    loadChildren: () =>
      import("./chat/chat.module").then((m) => m.ChatPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "chatmessages",
    loadChildren: () =>
      import("./chatmessages/chatmessages.module").then(
        (m) => m.ChatmessagesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "marketplacenewphotos",
    loadChildren: () =>
      import("./marketplacenewphotos/marketplacenewphotos.module").then(
        (m) => m.MarketplacenewphotosPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "marketplacenewlisting",
    loadChildren: () =>
      import("./marketplacenewlisting/marketplacenewlisting.module").then(
        (m) => m.MarketplacenewlistingPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-listing",
    loadChildren: () =>
      import("./proppycares-listing/proppycares-listing.module").then(
        (m) => m.ProppycaresListingPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-create",
    loadChildren: () =>
      import("./proppycares-create/proppycares-create.module").then(
        (m) => m.ProppycaresCreatePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-photos",
    loadChildren: () =>
      import("./proppycares-photos/proppycares-photos.module").then(
        (m) => m.ProppycaresPhotosPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppypreloved-listing",
    loadChildren: () =>
      import("./proppypreloved-listing/proppypreloved-listing.module").then(
        (m) => m.ProppyprelovedListingPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppypreloved-create",
    loadChildren: () =>
      import("./proppypreloved-create/proppypreloved-create.module").then(
        (m) => m.ProppyprelovedCreatePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppypreloved-photos",
    loadChildren: () =>
      import("./proppypreloved-photos/proppypreloved-photos.module").then(
        (m) => m.ProppyprelovedPhotosPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-terms",
    loadChildren: () =>
      import("./proppycares-terms/proppycares-terms.module").then(
        (m) => m.ProppycaresTermsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "marketplace-terms",
    loadChildren: () =>
      import("./marketplace-terms/marketplace-terms.module").then(
        (m) => m.MarketplaceTermsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppypreloved-terms",
    loadChildren: () =>
      import("./proppypreloved-terms/proppypreloved-terms.module").then(
        (m) => m.ProppyprelovedTermsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "diagnose-terms",
    loadChildren: () =>
      import("./diagnose-terms/diagnose-terms.module").then(
        (m) => m.DiagnoseTermsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppychat-inbox",
    loadChildren: () =>
      import("./proppychat-inbox/proppychat-inbox.module").then(
        (m) => m.ProppychatInboxPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "registration/:id",
    loadChildren: () =>
      import("./registration/registration.module").then(
        (m) => m.RegistrationPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "registration/:id/:rentag",
    loadChildren: () =>
      import("./registration/registration.module").then(
        (m) => m.RegistrationPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "registration",
    loadChildren: () =>
      import("./registration/registration.module").then(
        (m) => m.RegistrationPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "country-codes-dropdown",
    loadChildren: () =>
      import(
        "./reusables/country-codes-dropdown/country-codes-dropdown.module"
      ).then((m) => m.CountryCodesDropdownPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "mydeals",
    loadChildren: () =>
      import("./mydeals/mydeals.module").then((m) => m.MydealsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "property-details/:id",
    loadChildren: () =>
      import("./property-details/property-details.module").then(
        (m) => m.PropertyDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "property-details",
    loadChildren: () =>
      import("./property-details-v1/property-details-v1.module").then(
        (m) => m.PropertyDetailsV1PageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "property-agent/:id",
    loadChildren: () =>
      import("./property-agent/property-agent.module").then(
        (m) => m.PropertyAgentPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'property-agent',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./agent-profilev2/agent-profilev2.module').then(m => m.AgentProfilev2PageModule),
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: "merchantdetails/:id",
    loadChildren: () =>
      import("./merchantdetails/merchantdetails.module").then(
        (m) => m.MerchantdetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-listing/:id",
    loadChildren: () =>
      import("./businesses-own/businesses-own.module").then(
        (m) => m.BusinessesOwnPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "password-reset",
    loadChildren: () =>
      import("./password-reset/password-reset.module").then(
        (m) => m.PasswordResetPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "chatinbox",
    loadChildren: () =>
      import("./chatinbox/chatinbox.module").then((m) => m.ChatinboxPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "add-review-rating-screen",
    loadChildren: () =>
      import("./add-review-rating-screen/add-review-rating-screen.module").then(
        (m) => m.AddReviewRatingScreenPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "global-search-by",
    loadChildren: () =>
      import("./global-search-by/global-search-by.module").then(
        (m) => m.GlobalSearchByPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "endorsement-list",
    loadChildren: () =>
      import("./endorsement-list/endorsement-list.module").then(
        (m) => m.EndorsementListPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "add-endorsement/:id1/:id2",
    loadChildren: () =>
      import("./add-endorsement/add-endorsement.module").then(
        (m) => m.AddEndorsementPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "endorsement-display-screen",
    loadChildren: () =>
      import(
        "./endorsement-display-screen/endorsement-display-screen.module"
      ).then((m) => m.EndorsementDisplayScreenPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "myqrcode",
    loadChildren: () =>
      import("./myqrcode/myqrcode.module").then((m) => m.MyqrcodePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "myaccount",
    loadChildren: () =>
      import("./myaccount/myaccount.module").then((m) => m.MyaccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "myaccountupdate",
    loadChildren: () =>
      import("./myaccountupdate/myaccountupdate.module").then(
        (m) => m.MyaccountupdatePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "propertylist-map",
    loadChildren: () =>
      import("./propertylist-map/propertylist-map.module").then(
        (m) => m.PropertylistMapPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "merchants-map",
    loadChildren: () =>
      import("./merchants-mapv2/merchants-mapv2.module").then(
        (m) => m.MerchantsMapv2PageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "myaccountprofileupdate",
    loadChildren: () =>
      import("./myaccountprofileupdate/myaccountprofileupdate.module").then(
        (m) => m.MyaccountprofileupdatePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "google-geocoding",
    loadChildren: () =>
      import("./google-geocoding/google-geocoding.module").then(
        (m) => m.GoogleGeocodingPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "poppycares-create-wiki",
    loadChildren: () =>
      import("./poppycares-create-wiki/poppycares-create-wiki.module").then(
        (m) => m.PoppycaresCreateWikiPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-create-wiki",
    loadChildren: () =>
      import("./proppycares-create-wiki/proppycares-create-wiki.module").then(
        (m) => m.ProppycaresCreateWikiPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "360/home",
    loadChildren: () =>
      import("./community360/home/home.module").then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "globalsearch",
    loadChildren: () =>
      import("./modals/globalsearch/globalsearch.module").then(
        (m) => m.GlobalsearchPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "add-property-to-market-terms",
    loadChildren: () =>
      import(
        "./add-property-to-market-terms/add-property-to-market-terms.module"
      ).then((m) => m.AddPropertyToMarketTermsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "map-marker-selection",
    loadChildren: () =>
      import(
        "./reusables/map-marker-selection/map-marker-selection.module"
      ).then((m) => m.MapMarkerSelectionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-create-v2",
    loadChildren: () =>
      import("./proppycares-create-v2/proppycares-create-v2.module").then(
        (m) => m.ProppycaresCreateV2PageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "fav-bookmarks-list",
    loadChildren: () =>
      import("./fav-bookmarks-list/fav-bookmarks-list.module").then(
        (m) => m.FavBookmarksListPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "report",
    loadChildren: () =>
      import("./report/report.module").then((m) => m.ReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "thankyou-screen",
    loadChildren: () =>
      import("./thankyou-screen/thankyou-screen.module").then(
        (m) => m.ThankyouScreenPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "endorsedlist",
    loadChildren: () =>
      import("./endorsedlist/endorsedlist.module").then(
        (m) => m.EndorsedlistPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "memberprofile/:id",
    loadChildren: () =>
      import("./member-public-profile/member-public-profile.module").then(
        (m) => m.MemberPublicProfilePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "memberprofile",
    loadChildren: () =>
      import("./member-public-profile/member-public-profile.module").then(
        (m) => m.MemberPublicProfilePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "ekyc",
    loadChildren: () =>
      import("./ekyc/ekyc.module").then((m) => m.EkycPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "ekyc-doc",
    loadChildren: () =>
      import("./ekyc-doc/ekyc-doc.module").then((m) => m.EkycDocPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "own-property-add",
    loadChildren: () =>
      import("./own-property-add/own-property-add.module").then(
        (m) => m.OwnPropertyAddPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "own-property-list",
    loadChildren: () =>
      import("./own-property-list/own-property-list.module").then(
        (m) => m.OwnPropertyListPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-modal-info",
    loadChildren: () =>
      import("./proppycares-modal-info/proppycares-modal-info.module").then(
        (m) => m.ProppycaresModalInfoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "propertylist-grid",
    loadChildren: () =>
      import("./propertylist-grid/propertylist-grid.module").then(
        (m) => m.PropertylistGridPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "marketplace-media-popup",
    loadChildren: () =>
      import("./marketplace-media-popup/marketplace-media-popup.module").then(
        (m) => m.MarketplaceMediaPopupPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "marketplacenewlisting-modal-info",
    loadChildren: () =>
      import(
        "./marketplacenewlisting-modal-info/marketplacenewlisting-modal-info.module"
      ).then((m) => m.MarketplacenewlistingModalInfoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-deal",
    loadChildren: () =>
      import("./proppy-deal/proppy-deal.module").then(
        (m) => m.ProppyDealPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-wishlist",
    loadChildren: () =>
      import("./proppy-wishlist/proppy-wishlist.module").then(
        (m) => m.ProppyWishlistPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-createaneed",
    loadChildren: () =>
      import("./proppy-createaneed/proppy-createaneed.module").then(
        (m) => m.ProppyCreateaneedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-mywishlist",
    loadChildren: () =>
      import("./proppy-mywishlist/proppy-mywishlist.module").then(
        (m) => m.ProppyMywishlistPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-comingsoon",
    loadChildren: () =>
      import("./proppy-comingsoon/proppy-comingsoon.module").then(
        (m) => m.ProppyComingsoonPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-chest",
    loadChildren: () =>
      import("./proppy-chest/proppy-chest.module").then(
        (m) => m.ProppyChestPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-earn",
    loadChildren: () =>
      import("./proppy-earn/proppy-earn.module").then(
        (m) => m.ProppyEarnPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-transfergem",
    loadChildren: () =>
      import("./proppy-transfergem/proppy-transfergem.module").then(
        (m) => m.ProppyTransfergemPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-packet",
    loadChildren: () =>
      import("./proppy-packet/proppy-packet.module").then(
        (m) => m.ProppyPacketPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-academy",
    loadChildren: () =>
      import("./proppy-academy-v1/proppy-academy-v1.module").then(
        (m) => m.ProppyAcademyV1PageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-dreamhome",
    loadChildren: () =>
      import("./proppy-dreamhome/proppy-dreamhome.module").then(
        (m) => m.ProppyDreamhomePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-beambassador",
    loadChildren: () =>
      import("./proppy-beambassador/proppy-beambassador.module").then(
        (m) => m.ProppyBeambassadorPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-aboutus",
    loadChildren: () =>
      import("./proppy-aboutus/proppy-aboutus.module").then(
        (m) => m.ProppyAboutusPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-academy-details",
    loadChildren: () =>
      import("./proppy-academy-details/proppy-academy-details.module").then(
        (m) => m.ProppyAcademyDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-academy-details/:id",
    loadChildren: () =>
      import("./proppy-academy-details/proppy-academy-details.module").then(
        (m) => m.ProppyAcademyDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-pink",
    loadChildren: () =>
      import("./proppy-pink/proppy-pink.module").then(
        (m) => m.ProppyPinkPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-claim/:id1/:id2",
    loadChildren: () =>
      import("./proppycares-claim/proppycares-claim.module").then(
        (m) => m.ProppycaresClaimPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "internet-connection-modal",
    loadChildren: () =>
      import(
        "./modals/internet-connection-modal/internet-connection-modal.module"
      ).then((m) => m.InternetConnectionModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "display-col-dropdown",
    loadChildren: () =>
      import(
        "./reusables/display-col-dropdown/display-col-dropdown.module"
      ).then((m) => m.DisplayColDropdownPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "photo-viewer",
    loadChildren: () =>
      import("./reusables/photo-viewer/photo-viewer.module").then(
        (m) => m.PhotoViewerPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "permission-modal",
    loadChildren: () =>
      import("./modals/permission-modal/permission-modal.module").then(
        (m) => m.PermissionModalPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-create-precheck",
    loadChildren: () =>
      import(
        "./proppycares-create-precheck/proppycares-create-precheck.module"
      ).then((m) => m.ProppycaresCreatePrecheckPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-create-precheck-activate",
    loadChildren: () =>
      import(
        "./proppycares-create-precheck-activate/proppycares-create-precheck-activate.module"
      ).then((m) => m.ProppycaresCreatePrecheckActivatePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "about-proppy",
    loadChildren: () =>
      import("./proppy-app/proppy-app.module").then(
        (m) => m.ProppyAppPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "merchant-popover",
    loadChildren: () =>
      import("./merchant-popover/merchant-popover.module").then(
        (m) => m.MerchantPopoverPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "business-add",
    loadChildren: () =>
      import("./business-add/business-add.module").then(
        (m) => m.BusinessAddPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "businesses-own",
    loadChildren: () =>
      import("./businesses-own/businesses-own.module").then(
        (m) => m.BusinessesOwnPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "businesses-own/:id",
    loadChildren: () =>
      import("./businesses-own/businesses-own.module").then(
        (m) => m.BusinessesOwnPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "business-found",
    loadChildren: () =>
      import("./business-found/business-found.module").then(
        (m) => m.BusinessFoundPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-gophermap",
    loadChildren: () =>
      import("./proppy-gophermap/proppy-gophermap.module").then(
        (m) => m.ProppyGophermapPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-news-add",
    loadChildren: () =>
      import("./proppy-news-add/proppy-news-add.module").then(
        (m) => m.ProppyNewsAddPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-news-display",
    loadChildren: () =>
      import("./proppy-news-display/proppy-news-display.module").then(
        (m) => m.ProppyNewsDisplayPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-news-moderator",
    loadChildren: () =>
      import("./proppy-news-moderator/proppy-news-moderator.module").then(
        (m) => m.ProppyNewsModeratorPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-news-share/:id",
    loadChildren: () =>
      import("./proppy-news-share/proppy-news-share.module").then(
        (m) => m.ProppyNewsSharePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycare-needs",
    loadChildren: () =>
      import("./proppycare-needs/proppycare-needs.module").then(
        (m) => m.ProppycareNeedsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "add-property-to-market-terms-v2",
    loadChildren: () =>
      import(
        "./add-property-to-market-terms-v2/add-property-to-market-terms-v2.module"
      ).then((m) => m.AddPropertyToMarketTermsV2PageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "bulletin-board-need-details",
    loadChildren: () =>
      import(
        "./bulletin-board-need-details/bulletin-board-need-details.module"
      ).then((m) => m.BulletinBoardNeedDetailsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycare-needs-create-order",
    loadChildren: () =>
      import(
        "./proppycare-needs-create-order/proppycare-needs-create-order.module"
      ).then((m) => m.ProppycareNeedsCreateOrderPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycare-needs-confirm-order/:id",
    loadChildren: () =>
      import(
        "./proppycare-needs-confirm-order/proppycare-needs-confirm-order.module"
      ).then((m) => m.ProppycareNeedsConfirmOrderPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "myaccountupdatemember",
    loadChildren: () =>
      import("./myaccountupdatemember/myaccountupdatemember.module").then(
        (m) => m.MyaccountupdatememberPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "add-property-to-market-v2",
    loadChildren: () =>
      import(
        "./add-property-to-market-v2/add-property-to-market-v2.module"
      ).then((m) => m.AddPropertyToMarketV2PageModule),
    // canActivate:[AuthGuard]
  },
  {
    path: "splash",
    loadChildren: () =>
      import("./splash/splash.module").then((m) => m.SplashPageModule),
  },
  {
    path: "web-landing",
    loadChildren: () =>
      import("./tab1/tab1-v1/tab1-v1.module").then((m) => m.Tab1V1PageModule),
  },
  {
    path: "propertylist",
    loadChildren: () =>
      import("./propertylist/propertylist.module").then(
        (m) => m.PropertylistPageModule
      ),
  },
  {
    path: "property-filter",
    loadChildren: () =>
      import("./property-filter/property-filter.module").then(
        (m) => m.PropertyFilterPageModule
      ),
  },
  {
    path: 'display-dropdown',
    loadChildren: () =>
      import('./reusables/display-dropdown/display-dropdown.module').then(m => m.DisplayDropdownPageModule)
  },
  {
    path: 'select-residents-type-drop-down',
    loadChildren: () =>
      import('./select-residents-type-drop-down/select-residents-type-drop-down.module').then(m => m.SelectResidentsTypeDropDownPageModule)
  },
  {
    path: 'send-to-marketplace',
    loadChildren: () => import('./send-to-marketplace/send-to-marketplace.module').then(m => m.SendToMarketplacePageModule)
  },
  {
    path: 'proppy-news-moderator-v2',
    loadChildren: () => import('./proppy-news-moderator-v2/proppy-news-moderator-v2.module').then(m => m.ProppyNewsModeratorV2PageModule)
  },
  {
    path: 'proppy-news-moderator-batchselection',
    loadChildren: () => import('./proppy-news-moderator-batchselection/proppy-news-moderator-batchselection.module').then(m => m.ProppyNewsModeratorBatchselectionPageModule)
  },
  {
    path: 'group-chat',
    loadChildren: () => import('./group-chat/group-chat.module').then(m => m.GroupChatPageModule)
  },
  {
    path: 'create-groups',
    loadChildren: () => import('./create-groups/create-groups.module').then(m => m.CreateGroupsPageModule)
  },
  {
    path: 'proppy-store-link',
    loadChildren: () => import('./proppy-store-link/proppy-store-link.module').then(m => m.ProppyStoreLinkPageModule)
  },
  {
    path: 'view-video',
    loadChildren: () => import('./view-video/view-video.module').then(m => m.ViewVideoPageModule)
  },
  {
    path: 'ordersummary',
    loadChildren: () => import('./ordersummary/ordersummary.module').then(m => m.OrdersummaryPageModule)
  },
  {
    path: 'unsubscribe-mop',
    loadChildren: () => import('./unsubscribe-mop/unsubscribe-mop.module').then(m => m.UnsubscribeMOPPageModule)
  },
  {
    path: 'mortageplan',
    loadChildren: () => import('./mortageplan/mortageplan.module').then(m => m.MortageplanPageModule)
  },
  {
    path: 'bulletin-board-agent-details',
    loadChildren: () => import('./bulletin-board-agent-details/bulletin-board-agent-details.module').then(m => m.BulletinBoardAgentDetailsPageModule)
  },
  // {
  //   path: 'mortgage-optimisation-plan',
  //   loadChildren: () => import('./mortgage-optimisation-plan/mortgage-optimisation-plan.module').then( m => m.MortgageOptimisationPlanPageModule)
  // },
  {
    path: 'mortgage-diagnosis',
    loadChildren: () => import('./mortgage-diagnosis/mortgage-diagnosis.module').then(m => m.MortgageDiagnosisPageModule)
  },
  {
    path: 'mortgage-diagnosis-report',
    loadChildren: () => import('./mortgage-diagnosis-report/mortgage-diagnosis-report.module').then(m => m.MortgageDiagnosisReportPageModule)
  },
  {
    path: 'popup1',
    loadChildren: () => import('./popup1/popup1.module').then(m => m.Popup1PageModule)
  },
  {
    path: 'popup2',
    loadChildren: () => import('./popup2/popup2.module').then(m => m.Popup2PageModule)
  },
  {
    path: "add-property-to-market-terms",
    loadChildren: () =>
      import(
        "./add-property-to-market-terms/add-property-to-market-terms.module"
      ).then((m) => m.AddPropertyToMarketTermsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "map-marker-selection",
    loadChildren: () =>
      import(
        "./reusables/map-marker-selection/map-marker-selection.module"
      ).then((m) => m.MapMarkerSelectionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-create-v2",
    loadChildren: () =>
      import("./proppycares-create-v2/proppycares-create-v2.module").then(
        (m) => m.ProppycaresCreateV2PageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "fav-bookmarks-list",
    loadChildren: () =>
      import("./fav-bookmarks-list/fav-bookmarks-list.module").then(
        (m) => m.FavBookmarksListPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "report",
    loadChildren: () =>
      import("./report/report.module").then((m) => m.ReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "thankyou-screen",
    loadChildren: () =>
      import("./thankyou-screen/thankyou-screen.module").then(
        (m) => m.ThankyouScreenPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "endorsedlist",
    loadChildren: () =>
      import("./endorsedlist/endorsedlist.module").then(
        (m) => m.EndorsedlistPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "memberprofile/:id",
    loadChildren: () =>
      import("./member-public-profile/member-public-profile.module").then(
        (m) => m.MemberPublicProfilePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "memberprofile",
    loadChildren: () =>
      import("./member-public-profile/member-public-profile.module").then(
        (m) => m.MemberPublicProfilePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "ekyc",
    loadChildren: () =>
      import("./ekyc/ekyc.module").then((m) => m.EkycPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "ekyc-doc",
    loadChildren: () =>
      import("./ekyc-doc/ekyc-doc.module").then((m) => m.EkycDocPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "own-property-add",
    loadChildren: () =>
      import("./own-property-add/own-property-add.module").then(
        (m) => m.OwnPropertyAddPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "own-property-list",
    loadChildren: () =>
      import("./own-property-list/own-property-list.module").then(
        (m) => m.OwnPropertyListPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-modal-info",
    loadChildren: () =>
      import("./proppycares-modal-info/proppycares-modal-info.module").then(
        (m) => m.ProppycaresModalInfoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "propertylist-grid",
    loadChildren: () =>
      import("./propertylist-grid/propertylist-grid.module").then(
        (m) => m.PropertylistGridPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "marketplace-media-popup",
    loadChildren: () =>
      import("./marketplace-media-popup/marketplace-media-popup.module").then(
        (m) => m.MarketplaceMediaPopupPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "marketplacenewlisting-modal-info",
    loadChildren: () =>
      import(
        "./marketplacenewlisting-modal-info/marketplacenewlisting-modal-info.module"
      ).then((m) => m.MarketplacenewlistingModalInfoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-deal",
    loadChildren: () =>
      import("./proppy-deal/proppy-deal.module").then(
        (m) => m.ProppyDealPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-wishlist",
    loadChildren: () =>
      import("./proppy-wishlist/proppy-wishlist.module").then(
        (m) => m.ProppyWishlistPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-createaneed",
    loadChildren: () =>
      import("./proppy-createaneed/proppy-createaneed.module").then(
        (m) => m.ProppyCreateaneedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-mywishlist",
    loadChildren: () =>
      import("./proppy-mywishlist/proppy-mywishlist.module").then(
        (m) => m.ProppyMywishlistPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-comingsoon",
    loadChildren: () =>
      import("./proppy-comingsoon/proppy-comingsoon.module").then(
        (m) => m.ProppyComingsoonPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-chest",
    loadChildren: () =>
      import("./proppy-chest/proppy-chest.module").then(
        (m) => m.ProppyChestPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-earn",
    loadChildren: () =>
      import("./proppy-earn/proppy-earn.module").then(
        (m) => m.ProppyEarnPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-transfergem",
    loadChildren: () =>
      import("./proppy-transfergem/proppy-transfergem.module").then(
        (m) => m.ProppyTransfergemPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-packet",
    loadChildren: () =>
      import("./proppy-packet/proppy-packet.module").then(
        (m) => m.ProppyPacketPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-academy",
    loadChildren: () =>
      import("./proppy-academy-v1/proppy-academy-v1.module").then(
        (m) => m.ProppyAcademyV1PageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-dreamhome",
    loadChildren: () =>
      import("./proppy-dreamhome/proppy-dreamhome.module").then(
        (m) => m.ProppyDreamhomePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-beambassador",
    loadChildren: () =>
      import("./proppy-beambassador/proppy-beambassador.module").then(
        (m) => m.ProppyBeambassadorPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-aboutus",
    loadChildren: () =>
      import("./proppy-aboutus/proppy-aboutus.module").then(
        (m) => m.ProppyAboutusPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-academy-details",
    loadChildren: () =>
      import("./proppy-academy-details/proppy-academy-details.module").then(
        (m) => m.ProppyAcademyDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-academy-details/:id",
    loadChildren: () =>
      import("./proppy-academy-details/proppy-academy-details.module").then(
        (m) => m.ProppyAcademyDetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-pink",
    loadChildren: () =>
      import("./proppy-pink/proppy-pink.module").then(
        (m) => m.ProppyPinkPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-claim/:id1/:id2",
    loadChildren: () =>
      import("./proppycares-claim/proppycares-claim.module").then(
        (m) => m.ProppycaresClaimPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "internet-connection-modal",
    loadChildren: () =>
      import(
        "./modals/internet-connection-modal/internet-connection-modal.module"
      ).then((m) => m.InternetConnectionModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "display-col-dropdown",
    loadChildren: () =>
      import(
        "./reusables/display-col-dropdown/display-col-dropdown.module"
      ).then((m) => m.DisplayColDropdownPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "photo-viewer",
    loadChildren: () =>
      import("./reusables/photo-viewer/photo-viewer.module").then(
        (m) => m.PhotoViewerPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "permission-modal",
    loadChildren: () =>
      import("./modals/permission-modal/permission-modal.module").then(
        (m) => m.PermissionModalPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-create-precheck",
    loadChildren: () =>
      import(
        "./proppycares-create-precheck/proppycares-create-precheck.module"
      ).then((m) => m.ProppycaresCreatePrecheckPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycares-create-precheck-activate",
    loadChildren: () =>
      import(
        "./proppycares-create-precheck-activate/proppycares-create-precheck-activate.module"
      ).then((m) => m.ProppycaresCreatePrecheckActivatePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "about-proppy",
    loadChildren: () =>
      import("./proppy-app/proppy-app.module").then(
        (m) => m.ProppyAppPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "merchant-popover",
    loadChildren: () =>
      import("./merchant-popover/merchant-popover.module").then(
        (m) => m.MerchantPopoverPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "business-add",
    loadChildren: () =>
      import("./business-add/business-add.module").then(
        (m) => m.BusinessAddPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "businesses-own",
    loadChildren: () =>
      import("./businesses-own/businesses-own.module").then(
        (m) => m.BusinessesOwnPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "businesses-own/:id",
    loadChildren: () =>
      import("./businesses-own/businesses-own.module").then(
        (m) => m.BusinessesOwnPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "business-found",
    loadChildren: () =>
      import("./business-found/business-found.module").then(
        (m) => m.BusinessFoundPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-gophermap",
    loadChildren: () =>
      import("./proppy-gophermap/proppy-gophermap.module").then(
        (m) => m.ProppyGophermapPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-news-add",
    loadChildren: () =>
      import("./proppy-news-add/proppy-news-add.module").then(
        (m) => m.ProppyNewsAddPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-news-display",
    loadChildren: () =>
      import("./proppy-news-display/proppy-news-display.module").then(
        (m) => m.ProppyNewsDisplayPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-news-moderator",
    loadChildren: () =>
      import("./proppy-news-moderator/proppy-news-moderator.module").then(
        (m) => m.ProppyNewsModeratorPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppy-news-share/:id",
    loadChildren: () =>
      import("./proppy-news-share/proppy-news-share.module").then(
        (m) => m.ProppyNewsSharePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycare-needs",
    loadChildren: () =>
      import("./proppycare-needs/proppycare-needs.module").then(
        (m) => m.ProppycareNeedsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "add-property-to-market-terms-v2",
    loadChildren: () =>
      import(
        "./add-property-to-market-terms-v2/add-property-to-market-terms-v2.module"
      ).then((m) => m.AddPropertyToMarketTermsV2PageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "bulletin-board-need-details",
    loadChildren: () =>
      import(
        "./bulletin-board-need-details/bulletin-board-need-details.module"
      ).then((m) => m.BulletinBoardNeedDetailsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycare-needs-create-order",
    loadChildren: () =>
      import(
        "./proppycare-needs-create-order/proppycare-needs-create-order.module"
      ).then((m) => m.ProppycareNeedsCreateOrderPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "proppycare-needs-confirm-order/:id",
    loadChildren: () =>
      import(
        "./proppycare-needs-confirm-order/proppycare-needs-confirm-order.module"
      ).then((m) => m.ProppycareNeedsConfirmOrderPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "myaccountupdatemember",
    loadChildren: () =>
      import("./myaccountupdatemember/myaccountupdatemember.module").then(
        (m) => m.MyaccountupdatememberPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "add-property-to-market-v2",
    loadChildren: () =>
      import(
        "./add-property-to-market-v2/add-property-to-market-v2.module"
      ).then((m) => m.AddPropertyToMarketV2PageModule),
    // canActivate:[AuthGuard]
  },
  {
    path: "splash",
    loadChildren: () =>
      import("./splash/splash.module").then((m) => m.SplashPageModule),
  },
  {
    path: "web-landing",
    loadChildren: () =>
      import("./tab1/tab1-v1/tab1-v1.module").then((m) => m.Tab1V1PageModule),
  },
  {
    path: "propertylist",
    loadChildren: () =>
      import("./propertylist/propertylist.module").then(
        (m) => m.PropertylistPageModule
      ),
  },
  {
    path: "property-filter",
    loadChildren: () =>
      import("./property-filter/property-filter.module").then(
        (m) => m.PropertyFilterPageModule
      ),
  },
  {
    path: 'display-dropdown',
    loadChildren: () =>
      import('./reusables/display-dropdown/display-dropdown.module').then(m => m.DisplayDropdownPageModule)
  },
  {
    path: 'select-residents-type-drop-down',
    loadChildren: () =>
      import('./select-residents-type-drop-down/select-residents-type-drop-down.module').then(m => m.SelectResidentsTypeDropDownPageModule)
  },
  {
    path: 'send-to-marketplace',
    loadChildren: () => import('./send-to-marketplace/send-to-marketplace.module').then(m => m.SendToMarketplacePageModule)
  },
  {
    path: 'proppy-news-moderator-v2',
    loadChildren: () => import('./proppy-news-moderator-v2/proppy-news-moderator-v2.module').then(m => m.ProppyNewsModeratorV2PageModule)
  },
  {
    path: 'proppy-news-moderator-batchselection',
    loadChildren: () => import('./proppy-news-moderator-batchselection/proppy-news-moderator-batchselection.module').then(m => m.ProppyNewsModeratorBatchselectionPageModule)
  },
  {
    path: 'group-chat',
    loadChildren: () => import('./group-chat/group-chat.module').then(m => m.GroupChatPageModule)
  },
  {
    path: 'create-groups',
    loadChildren: () => import('./create-groups/create-groups.module').then(m => m.CreateGroupsPageModule)
  },
  {
    path: 'proppy-store-link',
    loadChildren: () => import('./proppy-store-link/proppy-store-link.module').then(m => m.ProppyStoreLinkPageModule)
  },
  {
    path: 'view-video',
    loadChildren: () => import('./view-video/view-video.module').then(m => m.ViewVideoPageModule)
  },
  {
    path: 'ordersummary',
    loadChildren: () => import('./ordersummary/ordersummary.module').then(m => m.OrdersummaryPageModule)
  },
  {
    path: 'unsubscribe-mop',
    loadChildren: () => import('./unsubscribe-mop/unsubscribe-mop.module').then(m => m.UnsubscribeMOPPageModule)
  },
  {
    path: 'mortageplan',
    loadChildren: () => import('./mortageplan/mortageplan.module').then(m => m.MortageplanPageModule)
  },
  {
    path: 'bulletin-board-agent-details',
    loadChildren: () => import('./bulletin-board-agent-details/bulletin-board-agent-details.module').then(m => m.BulletinBoardAgentDetailsPageModule)
  },
  {
    path: 'mortgage-plan',
    loadChildren: () => import('./mortageplan/mortageplan.module').then(m => m.MortageplanPageModule)
  },
  {
    path: 'mortgage-diagnosis',
    loadChildren: () => import('./mortgage-diagnosis/mortgage-diagnosis.module').then(m => m.MortgageDiagnosisPageModule)
  },
  {
    path: 'mortgage-diagnosis-report',
    loadChildren: () => import('./mortgage-diagnosis-report/mortgage-diagnosis-report.module').then(m => m.MortgageDiagnosisReportPageModule)
  },
  {
    path: 'my-wish-list',
    loadChildren: () => import('./my-wish-list/my-wish-list.module').then(m => m.MyWishListPageModule)
  },

  {
    path: 'popup1',
    loadChildren: () => import('./popup1/popup1.module').then(m => m.Popup1PageModule)
  },
  {
    path: 'popup2',
    loadChildren: () => import('./popup2/popup2.module').then(m => m.Popup2PageModule)
  },
  {
    path: 'make-an-offer',
    loadChildren: () => import('./make-an-offer/make-an-offer.module').then(m => m.MakeAnOfferPageModule)
  },
  {
    path: 'questionnaire',
    loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnairePageModule)
  },
  {
    path: 'popup3',
    loadChildren: () => import('./popup3/popup3.module').then(m => m.Popup3PageModule)
  },
  {
    path: 'wish',
    loadChildren: () => import('./my-wish/my-wish.module').then(m => m.MyWishPageModule)
  },
  {
    path: 'my-wish-list-filter',
    loadChildren: () => import('./my-wish-list-filter/my-wish-list-filter.module').then(m => m.MyWishListFilterPageModule)
  },
  {
    path: 'my-referrals',
    loadChildren: () => import('./my-referrals/my-referrals.module').then(m => m.MyReferralsPageModule)
  },
  {
    path: 'proppy-gophermap-list',
    loadChildren: () => import('./proppy-gophermap-list/proppy-gophermap-list.module').then(m => m.ProppyGophermapListPageModule)
  },
  {
    path: 'proppy-gophermap-filter',
    loadChildren: () => import('./proppy-gophermap-filter/proppy-gophermap-filter.module').then(m => m.ProppyGophermapFilterPageModule)
  },
  {
    path: 'proppy-wizard',
    loadChildren: () => import('./reusables/proppy-wizard/proppy-wizard.module').then(m => m.ProppyWizardPageModule)
  },
  {
    path: 'proppy-homes',
    loadChildren: () => import('./proppy-homes/proppy-homes.module').then(m => m.ProppyHomesPageModule)
  },
  {
    path: 'agent-profilev2',
    loadChildren: () => import('./agent-profilev2/agent-profilev2.module').then(m => m.AgentProfilev2PageModule)
  },
  {
    path: 'agency-profile',
    loadChildren: () => import('./agency-profile/agency-profile.module').then(m => m.AgencyProfilePageModule)
  },
  {
    path: 'proppy-needs-process-tracker',
    loadChildren: () => import('./proppy-needs-process-tracker/proppy-needs-process-tracker.module').then(m => m.ProppyNeedsProcessTrackerPageModule)
  },
  {
    path: 'property-agent-activation',
    loadChildren: () => import('./property-agent-activation/property-agent-activation.module').then(m => m.PropertyAgentActivationPageModule)
  },
  {
    path: 'uicongratulations',
    loadChildren: () => import('./uicomponents/uicongratulations/uicongratulations.module').then(m => m.UicongratulationsPageModule)
  },
  {
    path: 'developer-gallery',
    loadChildren: () => import('./developer-gallery/developer-gallery.module').then(m => m.DeveloperGalleryPageModule)
  },
  {
    path: 'wish-list-suggest-properties',
    loadChildren: () => import('./wish-list-suggest-properties/wish-list-suggest-properties.module').then(m => m.WishListSuggestPropertiesPageModule)
  },
  {
    path: 'bulletin-board-agent-details-wishlist',
    loadChildren: () => import('./bulletin-board-agent-details-wishlist/bulletin-board-agent-details-wishlist.module').then(m => m.BulletinBoardAgentDetailsWishlistPageModule)
  },
  {
    path: 'appeal-board',
    loadChildren: () => import('./appeal-board/appeal-board.module').then(m => m.AppealBoardPageModule)
  },
  {
    path: 'premium-merchant',
    loadChildren: () => import('./premium-merchant/premium-merchant.module').then(m => m.PremiumMerchantPageModule)
  },
  {
    path: 'display-qr',
    loadChildren: () => import('./uicomponents/display-qr/display-qr.module').then(m => m.DisplayQrPageModule)
  },
  {
    path: 'amazing-race',
    loadChildren: () => import('./amazing-race/amazing-race.module').then(m => m.AmazingRacePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'preloved',
    loadChildren: () => import('./proppy-preloved/preloved/preloved.module').then(m => m.PrelovedPageModule)
  },
  {
    path: 'queue-management',
    loadChildren: () => import('./queue-management/queue-management.module').then(m => m.QueueManagementPageModule)
  },
  {
    path: 'earn',
    loadChildren: () => import('./earn/earn.module').then(m => m.EarnPageModule)
  },
  {
    path: 'queue-management',
    loadChildren: () => import('./queue-management/queue-management.module').then(m => m.QueueManagementPageModule)
  },
  {
    path: 'proppy-doc',
    loadChildren: () => import('./proppy-doc/proppy-doc.module').then(m => m.ProppyDocPageModule)
  },
  {
    path: 'preloved-buybtn',
    loadChildren: () => import('./proppy-preloved/preloved-buybtn/preloved-buybtn.module').then(m => m.PrelovedBuybtnPageModule)
  },
  {
    path: 'mortage-agreement',
    loadChildren: () => import('./mortage-agreement/mortage-agreement.module').then(m => m.MortageAgreementPageModule)
  },
  {
    path: 'security-verification',
    loadChildren: () => import('./security-verification/security-verification.module').then(m => m.SecurityVerificationPageModule)
  },
  {
    path: 'privacy-settings',
    loadChildren: () => import('./privacy-settings/privacy-settings.module').then(m => m.PrivacySettingsPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./delete-account/delete-account.module').then(m => m.DeleteAccountPageModule)
  },
  {
    path: 'delete-account-back-proppy',
    loadChildren: () => import('./delete-account-back-proppy/delete-account-back-proppy.module').then(m => m.DeleteAccountBackProppyPageModule)
  },
  {
    path: 'mobile-email-verification',
    loadChildren: () => import('./mobile-email-verification/mobile-email-verification.module').then(m => m.MobileEmailVerificationPageModule)
  },
  // {
  //   path: 'uicalendar',
  //   loadChildren: () => import('./uicomponents/uicalendar/uicalendar.module').then( m => m.UicalendarPageModule)
  // },
  {
    path: 'proppy-cares',
    loadChildren: () => import('./proppy-cares/proppy-cares.module').then(m => m.ProppyCaresPageModule)
  },
  {
    path: 'filter-modal',
    loadChildren: () => import('./uicomponents/filter-modal/filter-modal.module').then(m => m.FilterModalPageModule)
  },
  {
    path: 'proppy-logo',
    loadChildren: () => import('./proppy-logo/proppy-logo.module').then(m => m.ProppyLogoPageModule)
  },
  {
    path: 'deposit-screen-modal',
    loadChildren: () => import('./deposit-screen-modal/deposit-screen-modal.module').then(m => m.DepositScreenModalPageModule)
  },
  {
    path: 'order-placed-popover',
    loadChildren: () => import('./order-placed-popover/order-placed-popover.module').then(m => m.OrderPlacedPopoverPageModule)
  },
  {
    path: 'my-membership',
    loadChildren: () => import('./my-membership/my-membership.module').then(m => m.MyMembershipPageModule)
  },
  {
    path: 'my-transtation-history',
    loadChildren: () => import('./my-transtation-history/my-transtation-history.module').then(m => m.MyTranstationHistoryPageModule)
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1-v1/tab1-v1.module').then(m => m.Tab1V1PageModule)
  },
  {
    path: 'find-agent',
    loadChildren: () => import('./find-agent/find-agent.module').then(m => m.FindAgentPageModule)
  },
  {
    path: 'needs-filter-categories',
    loadChildren: () => import('./home-page/needs-filter-categories/needs-filter-categories.module').then(m => m.NeedsFilterCategoriesPageModule)
  },
  {
    path: 'property-details-v1',
    loadChildren: () => import('./property-details-v1/property-details-v1.module').then(m => m.PropertyDetailsV1PageModule)
  },
  {
    path: 'voucher-view-all',
    loadChildren: () => import('./home-page/proppy-gophermap-listv2/voucher-view-all/voucher-view-all.module').then(m => m.VoucherViewAllPageModule)
  },
  {
    path: 'proppy-academy-v1',
    loadChildren: () => import('./proppy-academy-v1/proppy-academy-v1.module').then(m => m.ProppyAcademyV1PageModule)
  },
  {
    path: 'properties-comparison',
    loadChildren: () => import('./properties-comparison/properties-comparison.module').then(m => m.PropertiesComparisonPageModule)
  },
  {
    path: 'proppy-signature',
    loadChildren: () => import('./proppy-signature/proppy-signature.module').then( m => m.ProppySignaturePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'proppycares-home2',
    loadChildren: () => import('./proppycares-home2/proppycares-home2.module').then(m => m.ProppycaresHome2PageModule)
  },
  {
    path: 'business-card',
    loadChildren: () => import('./business-card/business-card.module').then(m => m.BusinessCardPageModule)
  },
  {
    path: 'personal-pcard',
    loadChildren: () => import('./personal-pcard/personal-pcard.module').then(m => m.PersonalPcardPageModule)
  },
  {
    path: 'proppy-wpe',
    loadChildren: () => import('./proppy-wpe/proppy-wpe.module').then( m => m.ProppyWpePageModule)
  },
  {
    path: 'proppy-homes-v2',
    loadChildren: () => import('@proppy/proppy-homes-pkg').then(m => m.ProppyHomesV2Module)
  },
  {
    path: 'pcard-contact-book',
    loadChildren: () => import('./pcard-contact-book/pcard-contact-book.module').then( m => m.PcardContactBookModule)
  },
  // ,
  // {
  //   path: 'language-popover',
  //   loadChildren: () => import('./language-popover/language-popover.module').then( m => m.LanguagePopoverPageModule)
  // }

  {
    path: 'my-profile',
    loadChildren: () => import('./ekyc-v2/my-profiles/my-profiles.module').then( m => m.MyProfilesPageModule)
  },
  {
    path: 'my-profile-settings',
    loadChildren: () => import('./my-profile-settings/my-profile-settings.module').then( m => m.MyProfileSettingsPageModule)
  },
  {
    path: 'contact-list',
    loadChildren: () => import('./contact-list/contact-list.module').then( m => m.ContactListPageModule)
  },
  {
    path: 'group-contact-list',
    loadChildren: () => import('./group-contact-list/group-contact-list.module').then( m => m.GroupContactListPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadModulesService, useHash : false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
