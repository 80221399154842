import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocSelectionPopoverComponent } from './doc-selection-popover.component';



@NgModule({
  declarations: [DocSelectionPopoverComponent],
  imports: [
    CommonModule
  ],
  exports:[DocSelectionPopoverComponent]
})
export class DocSelectionPopoverModule { }
