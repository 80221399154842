import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { Filesystem } from '@capacitor/filesystem';
import { DataFetcherService } from './data-fetcher/data-fetcher.service';
import { AlertHandlerService } from './alert-handler.service';
import { PhotoService } from './middleware/photo.service';
@Injectable({
  providedIn: 'root'
})
export class OpenFileUtilService {
  constructor(private plt: Platform,
    private filePicker: IOSFilePicker,
    private fileChooser: FileChooser,
    private file: File,
    private dataFetcherService: DataFetcherService,
    private photoService: PhotoService,
    private alertService: AlertHandlerService,
  ) { }

  async open(cb, file_type = '', file_types = []) {
    var obj: any = {}
    if (this.plt.is('android')) {
      const selectedFile: string = await this.fileChooser.open()
      obj['document_path'] = selectedFile
      const fileEntry = await this.file.resolveLocalFilesystemUrl(selectedFile) as any;
      fileEntry.file((fileInfo) => {
        obj['doc_type'] = fileInfo['type'] || ''
        obj['size'] = fileInfo['size'] || 0
        if ((file_type && !obj['doc_type'].includes(file_type)) || (file_types.length > 0 && !file_types.includes(obj['doc_type']))) {
          this.alertService.presenToast('Unsupported File Format, Please Try Again.')
          return
        }
        obj['doc_name'] = fileInfo['name']
        if (!obj['doc_name'].includes('.')) {
          obj['doc_name'] += ('.' + (obj['doc_type'].split('/')[1] || ''))
        }
        cb(obj)
      })
    } else {
      const selectedFile: string = await this.filePicker.pickFile();
      obj['document_path'] = 'file:///' + encodeURI(selectedFile)
      const fileEntry = await this.file.resolveLocalFilesystemUrl('file:///' + encodeURI(selectedFile)) as any;
      fileEntry.file((fileInfo) => {
        obj['doc_type'] = fileInfo['type']
        obj['size'] = fileInfo['size'] || 0
        if (file_type && !obj['doc_type'].includes(file_type)) {
          this.alertService.presenToast('Unsupported File Format, Please Try Again.')
          return
        }
        obj['doc_name'] = fileInfo['name']
        cb(obj)
      })
    }
  }
  async doFilesUpload(params, cb, fileKey = 'formFiles', pos = 0) {
    console.log(params, 'params');
    console.log(pos, 'pos');
    var fileObj = params.filesArr[pos] || {}
    var failedItems = []
    this.onFileUpload(fileObj, fileObj.postParams || params.postParams || {}, fileObj.url || params.url, (err, res) => {
      var status = (res || {})['status'] || false
      if (err || !status) {
        fileObj['res'] = err || res
        failedItems.push(fileObj)
      }
      if (pos < (params.filesArr.length - 1)) {
        this.doFilesUpload(params, cb, fileKey, ++pos)
      } else {
        cb({ 'msg': 'Failed to Upload ' + failedItems.length + ' out of ' + params.filesArr.length + '.', 'failedItems': failedItems, 'status': failedItems.length == 0 })
      }
    }, fileKey)
  }

  async onFileUpload(fileObj, postParams, url, cb, fileKey = 'formFiles') {
    if (fileObj['document_path'] && fileObj['document_path'].length > 0) {
      var uri = fileObj['document_path']
      const contents = await Filesystem.readFile({ path: uri, });
      const rawData = atob(contents.data);
      const bytes = new Array(rawData.length);
      for (var x = 0; x < rawData.length; x++) {
        bytes[x] = rawData.charCodeAt(x);
      }
      const arr = new Uint8Array(bytes);
      const blob = new Blob([arr], {
        type: fileObj['doc_type']
      });
      // var fileSizeInKB = blob.size / 1024;
      // var fileSizeInMB = fileSizeInKB / 1024;
      // if (fileSizeInMB < 5) {
        const formData = new FormData();
        formData.append(fileKey, blob, fileObj['doc_name']);
        Object.keys(postParams).map(key => {
          formData.append(key, postParams[key]);
        })
        this.uploadServiceCall(formData, url, cb);
      // } else {
      //   cb({ "error": "File Size Can't Be More Than 5MB." }, null)
      // }
    } else {
      cb({ "error": "Please Select Document To Upload" }, null)
    }
  }
  async uploadServiceCall(formData: FormData, url, cb) {
    await this.dataFetcherService.doCdnUploadRequest(url, formData)
      .subscribe((res) => {
        cb(null, res)
      }, (error) => {
        cb(error, null)
      });
  }

  openVideo(source_type, cb, duration) {
    if (source_type == SourceType.camera) {
      var obj = {}
      this.photoService.getVideo(1, 1, duration).then(async (videos: any[]) => {
        var video = videos[0];
        obj['document_path'] = video.fullPath
        const fileEntry = await this.file.resolveLocalFilesystemUrl(obj['document_path']) as any;
        fileEntry.file((fileInfo) => {
          obj['doc_type'] = fileInfo['type'] || ''
          obj['doc_name'] = fileInfo['name']
          if (!obj['doc_name'].includes('.')) {
            obj['doc_name'] += ('.' + (obj['doc_type'].split('/')[1] || ''))
          }
          obj['doc_web_path'] = video['fullPath']
          cb([obj])
        })
      });
    } else {
      this.open(cb, MimeTypes.video_type)
    }
  }

  openImage(source_type, cb, limit) {
    if (source_type == SourceType.camera) {
      var obj: any = {}
      this.photoService.getPicture(1, limit).then(async image => {
        obj['document_path'] = image["path"]
        const fileEntry = await this.file.resolveLocalFilesystemUrl(obj['document_path']) as any;
        fileEntry.file((fileInfo) => {
          console.log(fileInfo,"fileinfoo");          
          obj['doc_type'] = fileInfo['type'] || ''
          obj['doc_name'] = fileInfo['name']
          if (!obj['doc_name'].includes('.')) {
            obj['doc_name'] += ('.' + (obj['doc_type'].split('/')[1] || ''))
          }
          obj['doc_web_path'] = image['webPath']
          console.log(obj,"obbjjj");          
          cb([obj])
        })
      });
    } else {
      this.photoService.getPicture(2, limit).then(photos => {
        const imgArr = photos["photos"] || photos;
        console.log(photos,'photos');        
        let tmpArr = []
        imgArr.map(async (image, indx) => {
          console.log(image,indx);          
          var obj: any = {}
          obj['document_path'] = image["path"]
          console.log(obj,"objjj");          
          const fileEntry = await this.file.resolveLocalFilesystemUrl(obj['document_path']) as any;
          fileEntry.file((fileInfo) => {
            console.log(fileInfo,"fileinfo");
            obj['doc_type'] = fileInfo['type'] || ''
            obj['doc_name'] = fileInfo['name']
            if (!obj['doc_name'].includes('.')) {
              obj['doc_name'] += ('.' + (obj['doc_type'].split('/')[1] || ''))
            }
            obj['doc_web_path'] = image['webPath']
          })
          tmpArr.push(obj)
          if (tmpArr.length > limit) {
            this.alertService.presenToast("Only the first " + limit + " images selected will be uploaded.")
          }
          if (indx == imgArr['length'] - 1) {
            cb(tmpArr.slice(0, limit))
          }
        })
      });
    }
  }

  selectFile(cb, file_type = '', source_type = '', limit = 1, duration = null) {
    switch (file_type) {
      case MimeTypes.image_type: {
        this.openImage(source_type, cb, limit)
        break;
      }
      case MimeTypes.video_type: {
        this.openVideo(source_type, cb, duration)
        break;
      }
      case MimeTypes.pdf_type: {
        this.open(cb, file_type)
        break;
      }
      case MimeTypes.word_type: {
        this.open(cb, file_type)
        break;
      }
      case MimeTypes.excel_type: {
        this.open(cb, file_type)
        break;
      }
      default: {
        this.open(cb, file_type)
        break;
      }
    }
  }

  checkFilesize(filesize: number,comparesize: number){
    let status
    var fileSizeInKB = filesize / 1024;
    var filesizeinMb = fileSizeInKB / 1024;
    console.log(filesizeinMb);
    if(comparesize >= filesizeinMb){
      status = true
    }
    else {
      status = false;
    }
    console.log(status);
    return status;
  }
}
export enum MimeTypes {
  image_type = 'image',
  video_type = 'video',
  pdf_type = 'pdf',
  word_type = 'msword',
  excel_type = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet/vnd.ms-excel',
}

export enum SourceType {
  camera = 1,
  gallery = 2
}
