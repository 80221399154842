import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { AlertHandlerService } from 'src/app/services/alert-handler.service';
import { ApiFetcherService } from 'src/app/services/api-fetcher.service';
import { ApiService } from 'src/app/services/api.service';
import { LanguageService } from 'src/app/services/language.service';
import { StorageService } from 'src/app/services/storage-service.service';
import { ProDataTypes, ProElementStyle, ProImageIcon } from '../enums';
import { UIControl } from '../uicontrol';
import { PhotoViewerPage } from 'src/app/reusables/photo-viewer/photo-viewer.page';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { DocSelectionPopoverComponent } from 'src/app/tab3/doc-selection-popover/doc-selection-popover.component';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-review-rating-details',
  templateUrl: './review-rating-details.component.html',
  styleUrls: ['./review-rating-details.component.scss'],
})
export class ReviewRatingDetailsComponent implements OnInit {
  @Input() ObjectType : string = '';
  @Input() ObjectID :string = '';
  @Input() hasAddProvision : boolean=true
  @Input() hasDisplayProvision : boolean=true
  @Input() type:string = '';
  @Input() page:string = '';
  @Input() PID:string = '';
  
  ObjectName = ''
  ObjectImageUrl = ''
  Language = 'en'
  remarkTagIds = []
  ratings_count = []
  ratings_details = []
  merchant_data = []
  user_ratings_details=[]
  params = { 'creator_user_id': '', 'rating': 0, 'comments': '', 'object_type': '', 'object_id': '', 'like_status': 's', 'user_id': 0, 'review_rating_tag_ids': [] }
  array = [1,2,3,4,5];
  TagReasons = []
  hashTagReasonsCount = []
  slideOpts = {
    slidesPerView: 3,
    spaceBetween: 5,
    speed: 400,
    loop: false,
    centeredSlides: false,
  }
  submit_btn: UIControl
  displayParams = {
    'object_id': '',
    'object_type': '',
    'is_with_comment': 0,
    'rr_tag_id': 0
  }
  ratings_count_Title = ['All','With Comment'];
  selected_display_type:number = 1
  
  isModal=false
  searchText:string="";
  tempData: any;
  skeleton_load : any ={
    rate_review : false,
    review_rating_details: true
  }
  constructor(
    private apiFetcher: ApiFetcherService,
    private languageService: LanguageService,
    private storage: Storage,
    private storageService: StorageService,
    private alerthandler: AlertHandlerService,
    public apiService: ApiService,
    public navCtrl : NavController,
    private modalCtrl:ModalController,
    private previewAnyFile: PreviewAnyFile,
    private pop_over_controller:PopoverController
    ) {
    this.Language = this.languageService.selected || 'en';
  }

  ngOnInit() {
    if(this.hasAddProvision){
      // this.getMerchantDetails()
      this.getReviewRatingTags();
      this.setUpView();
    }
    if(this.hasDisplayProvision){
      this.getReviewRatingTagsCount();
      this.getReviewRateCount();
      this.viewReviewsAndRatings()
    }
    this.storageService.getString('Proppy_UserID',pid=>{
      if((this.PID || '').length == 0) {
        this.PID = pid;
      }
      this.params['creator_user_id'] = this.PID
      this.getUserReviewsAndRatings()
    })
  }
  setUpView() {
    this.submit_btn = new UIControl(ProDataTypes.Button, 2, "Submit Review & Rating");
    this.submit_btn.elementSize = 6;
    this.submit_btn.elementStyle = ProImageIcon.PencilOutline
    this.submit_btn.elementStyle = ProElementStyle.PrimaryGradiant
  }
  ratingClick(val, index) {
    if (this.params['rating'] == val) {
      this.params['rating'] = 0
    }
    else {
      this.params['rating'] = val;
    }
  }
  onTagSelected(item) {
    if (this.remarkTagIds.includes(item['id'])) {
      this.remarkTagIds = this.remarkTagIds.filter(f => f != item['id']);
    } else {
      this.remarkTagIds.push(item['id'])
    }
    //console.log(this.remarkTagIds);
  }
  async onSaveReviewRatingClick() {
    await this.alerthandler.presentLoading('Adding Review..')
    this.params['object_id'] = this.ObjectID;
    this.params['object_type'] = this.ObjectType;
    // this.storageService.getString('Proppy_UserID', (id) => {
      this.params['creator_user_id'] = this.PID;
      this.params['review_rating_tag_ids'] = this.remarkTagIds;
      if((this.params['comments'].trim() || '').length==0){
        delete this.params['comments'];
      }
      this.apiFetcher.doPost(ApiService.ADD_REVIEW_RATING, this.params).subscribe((res) => {
          this.alerthandler.dismissLoading()
          const status=res['status'] || false
          if (status) {
            this.alerthandler.dismissLoading()
            this.user_ratings_details=[this.params]
            this.alerthandler.presenToast('Review Rating Submitted Successfully.');
            this.viewReviewsAndRatings()
          } else {
            this.alerthandler.dismissLoading()
            this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);
          }
        },err=>{
          this.alerthandler.dismissLoading()
          this.alerthandler.presenToast(err['error'] || ApiService.ERROR_TOAST_MSG);
        });
    // })
  }
  async showImgModel(selectedPos) {
    if(this.ratings_details[selectedPos]['image_url']==''){
      return
    }
    let imgs = []
    imgs.push(this.ratings_details[selectedPos]['image_url'])
    const modal = await this.modalCtrl.create({
      component: PhotoViewerPage,
      componentProps: {
        imgs: imgs,
        selectedPos: selectedPos,
      },
      presentingElement: await this.modalCtrl.getTop()
    })
    await modal.present();
    await modal.onWillDismiss().then((result) => {
    }).catch((err) => {
    });
  }

  async showProfilePic(profileImg:string){
    let imgs = [];
    imgs.push(profileImg)
    const modal = await this.modalCtrl.create({
      component: PhotoViewerPage,
      componentProps: {
        imgs: imgs,
        selectedPos: 0,
      },
      presentingElement: await this.modalCtrl.getTop()
    })
    await modal.present();
    await modal.onWillDismiss().then((result) => {
    }).catch((err) => {
    });
  }

  getReviewRatingTags() {
    var params_count = {
      'language': this.Language,
      'review_rating_type': this.ObjectType
    }
    this.skeleton_load['rate_review'] = true
    this.apiFetcher.doPost(ApiService.GET_REVIEW_RATING_HASHTAGS, params_count).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.TagReasons = res['data']
          this.skeleton_load['rate_review'] = false;
      } else {
        this.skeleton_load['rate_review'] = false;
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);      }
    }),
      (error) => {
        this.skeleton_load['rate_review'] = false;
        this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
      }
  }
  getReviewRatingTagsCount() {
    var params_tags_count = {
      'language': this.Language,
      'review_rating_type': this.ObjectType,
      'object_type': this.ObjectType,
      'object_id': this.ObjectID
    }
    this.apiFetcher.doPost(ApiService.GET_REVIEW_RATING_HASHTAGS, params_tags_count).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.hashTagReasonsCount = res['data']
      } else {
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);      }
    }),(error) => {
        this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
      }
  }
  saveReviewandRating() {
    this.apiFetcher.doPost(ApiService.ADD_REVIEW_RATING, this.params).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.alerthandler.dismissLoading()
        console.log(res['data']);
      } else {
        this.alerthandler.dismissLoading()
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);      }
    }),(error) => {
      this.alerthandler.dismissLoading()
        this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
      }
  }
  async getReviewRateCount() {
    let params = {}
    if(this.type.length>0 && (this.type == 'myAccount' || 'ProductService')){
       let creator_user_id = this.PID
       let objectArry = (this.type == 'myAccount' ? ['Merchant'] : (this.type == 'ProductService' ? ["Product", "ServiceProduct"] : ''))
      params = { 'creator_user_id': creator_user_id , 'object_type_arr': objectArry }
    }else{
      params = { 'object_id': this.ObjectID, 'object_type': this.ObjectType }
    }
    this.apiFetcher.doPost(ApiService.GET_REVIEW_RATING_COUNT, params).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.ratings_count = res['data']
      } else {
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);      }
    }),(error) => {
        this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
      }
  }
  viewReviewsAndRatings(hashtag_id=0) {
    this.skeleton_load['review_rating_details'] = true;
    this.displayParams['object_id'] = this.ObjectID;
    this.displayParams['object_type'] = this.ObjectType;
    this.displayParams['rr_tag_id']=hashtag_id
    if(this.type.length>0 && (this.type == 'myAccount' || 'ProductService') && this.array.includes(hashtag_id)){
      delete this.displayParams['is_with_comment'];
    }
    if(this.type == 'myAccount'){
      this.displayParams['creator_user_id'] = this.PID
      this.displayParams['is_merchant_info_required'] = 1
      this.displayParams['rating'] = hashtag_id
    }else if(this.type == 'ProductService'){
      delete this.displayParams['object_type']
      this.displayParams['creator_user_id'] = this.PID
      this.displayParams['object_type_arr'] = ["Product", "ServiceProduct"]
      this.displayParams['is_product_info_required'] = 1
      this.displayParams['rating'] = hashtag_id
    }
    this.apiFetcher.doPost(ApiService.GET_REVIEW_RATING_DETAILS, this.displayParams).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.ratings_details = res['data']
        this.skeleton_load['review_rating_details'] = false;
          if(this.ratings_details.length>0 && this.type.length>0 && (this.type == 'myAccount' || 'ProductService')){
            this.ratings_details?.map(r=>{
              if(r['object_type'] == 'ServiceProduct'){
                r['product_info'] = r['service_product_info']
              }
            });
            if(this.page.length>0 && this.page == 'public_profile'){
              this.ratings_details?.filter(r=> r['allow_public_view'] == 1);
            }
            this.tempData = JSON.parse(JSON.stringify(this.ratings_details));
          }
      }else {
        this.skeleton_load['review_rating_details'] = false;
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);      }
    }), (error) => {
      this.skeleton_load['review_rating_details'] = false;
        this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
      }
  }
  getUserReviewsAndRatings() {
    var params={...this.displayParams}
    params['is_with_comment']=0
    params['rr_tag_id']=0
    params['creator_user_id']=this.params['creator_user_id']

    // if(this.type == 'myAccount'){
    //   params['is_merchant_info_required'] = 1
    // }else if(this.type == 'ProductService'){
    //   params['object_type_arr'] = ["Product", "ServiceProduct"]
    //   params['is_product_info_required'] = 1
    // }
    
    this.apiFetcher.doPost(ApiService.GET_REVIEW_RATING_DETAILS, params).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.user_ratings_details = res['data']
      }else {
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);      }
    }), (error) => {
        this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
      }
  }
  getMerchantDetails() {
    this.skeleton_load['rate_review'] = true
    this.apiFetcher.doPost(ApiService.GET_PREMIUM_MERCHANT_DETAILS, { merchant_id: this.ObjectID }).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.merchant_data = res['data'][0]
        console.log(this.merchant_data);
          this.skeleton_load['rate_review'] = false;
      } else {
        this.skeleton_load['rate_review'] = false;
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);      }
    }), (error) => {
      this.skeleton_load['rate_review'] = false;
        this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
      }
  }

  onBackClick(){
    if(this.isModal){
      this.modalCtrl.dismiss()
    } else {
      this.navCtrl.back()
    }
  }
  doRefresh(event) {
    this.getUserReviewsAndRatings()
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  preview(path){
    this.previewAnyFile.preview(path)
  }

  async onMerchantEllipseOptions(event:any,item:any) {
    let cClass = '';
    if(this.page.length>0 && this.page == 'public_profile'){
      cClass = 'public_profile_pop_height'
    }
    const popover = await this.pop_over_controller.create({
      component: DocSelectionPopoverComponent,
      cssClass: `my-account-doc-popSelection border-full ${cClass}`,
      event: event,
      componentProps: {
        item:item,
        userId:this.params['creator_user_id'],
        hideStatus:item['allow_public_view'],
        tabType:(this.ObjectType == 'Merchant' ? 1 : 2),
        page:this.page
      },
    });
    popover.onDidDismiss().then((data) => {
      var val = data["data"] || {};
      if(Object.keys(val).length>0 && (val['confirm'] == 3 || val['confirm'] == 4)){
        //val['object']['allow_public_view'] = val['hide_status']
        //console.log(val,'val....');
        this.viewReviewsAndRatings()
      }
    });
    await popover.present()
  }

  searchMerchantorProductService(type:string){
    if(type == 'myAccount'){
      this.ratings_details = this.tempData?.filter(u => (u?.['merchant_info']?.['company_name']?.includes(this.searchText) || u?.['trx_date_time']?.includes(this.searchText) || u?.['comments']?.includes(this.searchText)));
    }else if(type == 'ProductService'){
      this.ratings_details = this.tempData?.filter(u => (u?.['product_info']?.['prod_name']?.includes(this.searchText) || u?.['trx_date_time']?.includes(this.searchText) || u?.['comments']?.includes(this.searchText)));
    }
  }
}