import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SingleInstace } from '../single-instance';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'responseType':'json',
    })
  };

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Please try again later.');
  };

 
  // onPost(url,params): Observable<any> {
  //   console.log(SingleInstace.getInstance().getTokenStr());
  //   this.httpOptions['headers']= new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   'proppy-token':SingleInstace.getInstance().getTokenStr()
  //     });
  //   console.log(this.httpOptions);
  //   return this.http.post<any>(url, JSON.stringify(params), this.httpOptions)
  //     .pipe(
  //       retry(2),
  //       catchError(this.handleError)
  //     )
  // }

  
  // onGet(url): Observable<any> {
  //   this.httpOptions['headers']= new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'proppy-token':SingleInstace.getInstance().getTokenStr()
  //       });
  //   return this.http
  //     .get<any>(url, this.httpOptions)
  //     .pipe(
  //       retry(2),
  //       catchError(this.handleError)
  //     )
  // }

  // // Update item by id
  // onPut(url,params): Observable<any> {
  //   this.httpOptions['headers']= new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   'proppy-token':SingleInstace.getInstance().getTokenStr()
  //  });
  //   return this.http
  //     .put<any>(url, JSON.stringify(params), this.httpOptions)
  //     .pipe(
  //       retry(2),
  //       catchError(this.handleError)
  //     )
  // }

  // // Delete item by id
  // onDelete(url) {
  //   return this.http
  //     .delete<any>(url)
  //     .pipe(
  //       retry(2),
  //       catchError(this.handleError)
  //     )
  // }

  onPost(url,body) {
    // this.setHttpOptions();
    return this.http.post<any>(
      url, body,this.httpOptions);
  }

  onPut(url,body) {
    // this.setHttpOptions();
    return this.http.put<any>(
      url, body,this.httpOptions);
  }

  onGet(url) {
    // this.setHttpOptions();
    return this.http.get<any>(url,this.httpOptions);
  }

  onDelete(url) {
    // this.setHttpOptions();
    return this.http.delete<any>(url,this.httpOptions);
  }
  

}
export const FILE_UPLOAD_MESSAGE = {
  FILE_FORMAT_ERROR: "File upload failed. Make sure the file is in .pdf format.",
  EMPTY_FILE_ERROR: "You are not allowed to upload an empty file.",
  FILE_EXCEED_MAX_SIZE_ERROR: "The file size must not exceeds 10 MB.",
  SINGLE_FILE_ERROR: "Only 1 file upload is allowed at a time.",
  FILE_NAME_LENGTH_TOO_LONG_ERROR: "The length of the file name cannot exceed 255 characters.",
  FILE_UPLOAD_FAILED_ERROR: "Failed to upload the file. Please try again.",
}
